import React, { useContext, useState } from 'react';
import { CanvasContext } from '../../UserContext'

import styles from '../css/canvas.module.css';

import tool_pen from '../../assets/group.svg';

import helpers from '../Helpers/Anims';

import CanvasUndo from './Undo'
import CanvasSelectImg from './SelectImg'

function CanvasDraw(props){
    const context = useContext(CanvasContext)

    async function MenuDraw(){ 

        if(props.activeMenuBg){
            props.setActiveMenuBg(false)
        }

        if(props.activeMenuText){
            props.setActiveMenuText(false)
        }
        context.canvas2.isDrawingMode = true;
        context.canvas2.freeDrawingBrush.width = props.inputValue
        context.canvas2.freeDrawingBrush.color = 'white';

        // console.log(`props.inputref`, props.inputref)

        
        await props.setActiveMenuDraw(true)

        if(!props.activeMenuDraw){
            helpers.showTool("#drawMenu")
        }

        // if( props.activeMenuDraw ){
            
        // } else {
        //     context.canvas2.isDrawingMode = false;
        // }
    }
    

    async function drawBrush(){ 
        context.canvas2.freeDrawingBrush.width = props.inputref.current.value;
        context.canvas2.freeDrawingBrush.color = 'white';

        props.setInputValue(props.inputref.current.value)
        // console.log(`draw brush`, props.inputValue)
    }


return (
    <>
        <button id='drawSelector' drawSelector onClick={MenuDraw} className={styles.select_button}>
            <img className={styles.select_img} src={tool_pen} /> 
            <span className='tooltip_text'>Draw</span>
        </button>

        { props.activeMenuDraw ? 
            <div id="drawMenu" className={styles.menu_draw}>
                <div className={styles.draw_selector}>
                    <input id={styles.input_draw} type='range' min='1' max='20' value={props.inputValue} ref={props.inputref}  onChange={drawBrush}/>
                    <p className={styles.drawing_line}> line width</p>
                </div>
                {/* <CanvasUndo h={props.h}/> */}
                <CanvasSelectImg setActiveMenuDrawFunction={props.setActiveMenuDraw} textType={props.textType} inputref={props.inputref}/>
            </div>
        : null }
    </>
    )
}

export default CanvasDraw;