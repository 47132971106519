import React , { useContext } from 'react';
import { fabric } from 'fabric';
import { CanvasContext } from '../../UserContext'

import styles from '../css/canvas.module.css';

import tool_text from '../../assets/tool_text.svg';

import helpers from '../Helpers/Anims';

import done from '../../assets/doneDrawing.svg'

function CanvasTextResponsivePicture(props){
    const context = useContext(CanvasContext)
    let  textbox;

    async function addText() {

        await props.setActiveMenuText(!props.activeMenuText)

        helpers.showToolResponsive("#textMenuResponsive")

        if(props.activeMenuBg){
            props.setActiveMenuBg(false)
        }

        if(props.activeMenuDraw){
            props.setActiveMenuDraw(false)
        }

        context.obj = context.canvas2.getObjects()
        
        let texts = [];

        context.obj.map((el, id) => {
            if(el.text !== undefined){
                texts.push(el)
            }
        })

        if( texts.length == 2){
            context.canvas2.isDrawingMode = false;

            textbox = new fabric.Textbox("Type your message here", {
                fontSize: 15,
                left: 35,
                top: 80,
                id: 'textbox',
                width: 250,
                textAlign: 'center',
                fill: '#fff',
                fontFamily: 'Open Sans',
                padding: 20,
                cornerSize: 18,
            });
            context.canvas2.discardActiveObject()
            // context.canvas2.setActiveObject(textbox)
            // textbox.enterEditing();

            textbox.selectAll()

            context.canvas2.add(textbox);
            context.canvas2.bringForward(textbox);

            textbox.on('changed', function() {
                var currentCharacters = textbox.text.length;
                let newFontSize;
                console.log('currentCharacters', currentCharacters)
    
                if(currentCharacters < 50){
                    newFontSize = 15;
                    textbox.set('fontSize', newFontSize);
                } else if(currentCharacters >= 50 && currentCharacters < 70){
                    newFontSize = 14;
                    textbox.set('fontSize', newFontSize);
                } else if (currentCharacters >= 100 && currentCharacters < 150){
                    newFontSize = 13;
                    textbox.set('fontSize', newFontSize);
                } else if (currentCharacters >= 150 ){
                    newFontSize = 12;
                    textbox.set('fontSize', newFontSize);
                }
    
            })

        } else {
            context.canvas2.isDrawingMode = false;
            const objetoEncontrado = texts.find(objeto => objeto.id === "textbox");
            console.log('objetoEncontrado', objetoEncontrado)
            let textbox1 = objetoEncontrado;
            context.canvas2.setActiveObject(textbox1)
            textbox1.enterEditing();
        }
    }

    async function setText( param, value){

        if(context.canvas2.getActiveObject()){
            var obj = context.canvas2.getActiveObject();

            if(obj.id != 'fabric_js'){
                obj.set( param, value);
                context.canvas2.renderAll();
                context.canvas2.discardActiveObject()
                context.canvas2.setActiveObject(obj)
                obj.enterEditing()
                context.canvas2.renderAll();
            }
        }
    }

    function doneText(){
        context.canvas2.discardActiveObject().renderAll();
        props.setActiveMenuText(!props.activeMenuText)
    }


return (
    <>
        <button id='textSelectorResponsive' onClick={addText} className={styles.select_button2}>
            <img className={styles.select_img} src={tool_text} /> 
            <p className={styles.p_description}>Text</p>
        </button>

        { props.activeMenuText ? 
            <>
                <div id="textMenuResponsive" className={styles.optionsDiv2}>
                    <div id='selector' className={styles.optionsDiv2_selector}>
                        <select ref={props.textType} className={styles.select_text} onChange={(e) => setText('fontFamily', props.textType.current.value)}>
                            <option value="Open Sans">Open Sans</option>
                            <option value="Crafty Girls">Crafty Girls</option>
                            <option value="Cedarville Cursive">Cedarville Cursive</option>
                            <option value="Rock Salt">Rock Salt</option>
                            <option value="Homemade Apple">Homemade Apple</option>
                            <option value="Shadows Into Light">Shadows Into Light</option>
                        </select>
                    </div>
                    
                    <button onClick={doneText} className={styles.select_button3}>
                        <img className={styles.selector} src={done} />
                        <p className={styles.drawing_p_done}> Done </p>
                    </button>
                </div>
                
            </>
        : null }
        
    </>
    )
}

export default CanvasTextResponsivePicture;