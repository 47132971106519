import gsap from 'gsap/gsap-core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MapInteraction from 'react-map-interaction';
import $ from "jquery"
/*
  This component provides a map like interaction to any content that you place in it. It will let
  the user zoom and pan the children by scaling and translating props.children using css.
*/
const MapInteractionCSS = (props) => {

      // BG PARALLAX



      const [isDragging, setIsDragging] = useState(false)
      const [adding, setAdding] = useState(false)


    useEffect(() => {

      // console.log(`props.disableParallax`, props.disableParallax)

        var rect = $('#containerWall')[0].getBoundingClientRect();
        var mouse = {x: 0, y: 0, moved: false};
  
        $("#containerWall").mousemove(function(e) {
          mouse.moved = true;
          mouse.x = e.clientX - rect.left;
          mouse.y = e.clientY - rect.top;
        });
  

        if(!props.disableParallax){
          gsap.ticker.add(function(){
            if (mouse.moved){ 
              // Check if dragging is happening from parent component
              if(!isDragging){
                parallaxIt("#container", -100);
              }
                parallaxIt("#containerWallImg", -30);
            }
            mouse.moved = false;
          })
        }

  
        // Ticker event will be called on every frame
        function parallaxIt(target, movement) {
            gsap.to(target, 1, {
                x: (mouse.x - rect.width / 2) / rect.width * movement,
                y: (mouse.y - rect.height / 2) / rect.height * movement
            });
        }
  
        // $(window).on('resize scroll', function(){
        //   rect = $('#containerWall')[0].getBoundingClientRect();
        // })
  
        $(".slide").click(function(e) {
            if($("#containerWall").hasClass("zoomed")){
                $("#containerWall").removeClass("zoomed");
            }else{
                $("#containerWall").addClass("zoomed");
            }
        });
    }, [adding, isDragging])


    // Use Effect to check state of parallax, when disabled we cancel parallax by using "sleep" callback of ticker function of gsap
    useEffect(() => {

      // console.log(`PARALLAX en Zoom`, props.disableParallax)


        if(props.disableParallax == false){
          // quick solution: Timeout to add parallax after zoomout animation from messages ends (ideal to have a actual callback from the function)
            setTimeout(() => {
              setAdding(!adding)
            }, 800);
        } else {
          gsap.ticker.sleep()
          // console.log(`sleep`, gsap.ticker)
        }
    }, [props.disableParallax])




  return (
    <MapInteraction {...props}>
      {
        ({translation, scale }) => {

          return (
            <div
              onDragStart={setIsDragging(true)}
              onDragEnd={setIsDragging(false)}
              id='zoomCanvasParent'
              style={{
                height: '100vh',
                width: '100vw',
                position: 'relative', // for absolutely positioned children
                overflow: 'hidden',
                touchAction: 'none', // Not supported in Safari :(
                msTouchAction: 'none',
                cursor: 'all-scroll',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
            >
              <div
                id='zoomCanvas'
                style={{
                  display: 'inline-block', // size to content
                  transform: `translate(${translation.x}px, ${translation.y}px) scale(${scale})`,
                  transformOrigin: '0 0 ',
                  // transition: 'all 0.7s ease-out 0s',
                  left: '',
                  top: '',
                  opacity: 1,
                  height: '100vh',
                  width: '100vw',
                  // scale: scale
                }}
              >
                {props.children}
              </div>
            </div>
          );
        }
      }
    </MapInteraction>
  );
};
export default MapInteractionCSS;
MapInteractionCSS.defaultProps = {
    minScale: 0.5,
    maxScale: 2,
    // translationBounds: ({
    //   xMin:-480 , xMax: 480, yMin: -270, yMax: 270
    // }),
    showControls: false,
    disablePan: false,
    disableZoom:false ,
    controlsClass: "buttonsControls",
    plusBtnClass: "buttonsControlsPlus",
    minusBtnClass: "buttonsControlsMinus",
}