import React from 'react';

import styles from '../css/popUpRotate.module.css';

function popUpRotate(){

return (
    <div class={styles.rotate_phone_please}>
        <div class={styles.phone}></div>  
        <div class={styles.message}>Please rotate your device 90°</div>
    </div>
    )
}

export default popUpRotate;