import React, { useEffect , useState, useContext, useLayoutEffect, memo, useRef} from 'react';
import './css/View.css';
import $ from "jquery"
import  MapInteractionCSS  from './Zoom';
import styles from './css/falsewall.module.css';
import hand_move from '../assets/hand_move.svg';
import footer from '../assets/footer-powered-by.svg'
import closeImgSvg from '../assets/closeWall.svg';
import playVideo from "../assets/play.svg"

import Hammer from 'hammerjs';

import { withRouter } from "react-router-dom";

import { faIgloo, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import helpers from './Helpers/Anims';


// import logo from '../assets/logo.png';
// import ProgressBar from './ProgressBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import { ScaleContext } from '../UserContext';
import { ConfigurationOptions } from 'aws-sdk';
import {gsap, Power3, Power4 } from 'gsap/gsap-core';

var allKeys = []
const allVideoSign = []
const imagesToAppend = []

var firstTouch = true;

var basePath = process.env.REACT_APP_S3_URL;
var cdn = "https://d320jtv022114v.cloudfront.net/"

var useCdn = false

if(useCdn){
  basePath = cdn
}


var firstSelectedPoint = true

var currentPos;


function Wall(props){
    const context = React.useContext(ScaleContext)

    var screen = context.screen
    var body = context.body


    const queryParams = new URLSearchParams(props.history.location.search)
    const singleParam = queryParams.get('message')


    let messageUrl;
    let sentMessage;

    var array1Length
    var array2Length

    var cantidadMessages

    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)

    useEffect(() => {

        context.setRender(false)

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)

                if(props.location.pathname === '/view'){

                    window.location.reload();
                }
            }, false);
        }
    
    }, [onRotate])



    const [valueWall, setValue] = useState({
        scale: 1,
        translation: { x: 0, y: 0 }
    });

      const [left, setLeft] = useState(0)
      const [top, setTop] = useState(0)


      const [disablePan, setDisablePan] = useState(false)
      const [disableZoom, setDisableZoom] = useState(false)

      const [disableParallax, setDisableParallax] = useState(true)
      

    useEffect( () => {

        messageUrl = localStorage.getItem('messageUrl');
        sentMessage = localStorage.getItem('sentMessage');


        if(sentMessage === 'true'){
            hideObjects()
            localStorage.setItem('sentMessage', 'false');
        }

        // console.log(`sentMessage`, sentMessage)

        allKeys = []

        $('#container').empty()

        const AWS = require('aws-sdk')
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACESS_KEY,
            region: 'us-east-1',
            acl: 'public-read'
        })

        // console.log(process.env)

        const s3 = new AWS.S3();
        var params = {
            Bucket: process.env.REACT_APP_BUCKET,
            Prefix: process.env.REACT_APP_BUCKET_PREFIX
        };

        

        s3.listObjectsV2(params, async function (err, data) {

            const sortedObjects = data.Contents.sort((a, b) => b.LastModified - a.LastModified);
            array1Length = data.Contents.length
            // console.log("Array 1", array1Length)

            cantidadMessages = data.Contents.length
            
            await sortedObjects.map( (element) => {

                console.log('element', element)
                if( element.Key.includes(".mp4") || element.Key.includes("videoSign")){

                } else if ( element.Key !== process.env.REACT_APP_BUCKET_PREFIX ) {
                    allKeys.push(element.Key)
                }
                
            });
            Looping();
        })

        function spiral(n) {
        // given n an index in the squared spiral
        // p the sum of point in inner squa re
        // a the position on the current square
        // n = p + a

        var r = Math.floor((Math.sqrt(n + 0) - 1) / 2) + 1;

        // compute radius : inverse arithmetic sum of 8+16+24+...=
        var p = (8 * r * (r - 1)) / 2;
        // compute total point on radius -1 : arithmetic sum of 8+16+24+...

        var en = r * 2;
        // points by face

        var a = (1 + n - p) % (r * 8);
        // compute de position and shift it so the first is (-r,-r) but (-r+1,-r)
        // so square can connect

        var pos = [0, 0, r];
            // eslint-disable-next-line default-case
            switch (Math.floor(a / (r * 2))) {
                // find the face : 0 top, 1 right, 2, bottom, 3 left 
                case 0:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = a - r;
                        pos[1] = -r;
                    }
                    break;
                case 1:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = r;
                        pos[1] = (a % en) - r;

                    }
                    break;
                case 2:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = r - (a % en);
                        pos[1] = r;
                    }
                    break;
                case 3:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = -r;
                        pos[1] = r - (a % en);
                    }
                    break;
            }
            return pos;
        }


        // function reDraw(){
        //     $('#container').children().remove()
        //     Looping()
        // }

        // 
        


        async function Looping(){
            // console.log(`looping`)

            // allKeys.reverse()
            // allKeys.unshift(allKeys.pop());
            $('#container').empty()
           
            let valueScreenWidthLooping = window.innerWidth;
            let valueScreenHeightLooping = window.innerHeight;


            let arrayThumbs = []



            const index0 = await allKeys.findIndex(element0 => element0 === `${process.env.REACT_APP_BUCKET_PREFIX}0.png`);
            if (index0 !== -1) {
                // Extraer el elemento encontrado
                const foundElement = allKeys.splice(index0, 1)[0];
            
                // Mover el elemento al inicio del array
                allKeys.unshift(foundElement);
            }


            await allKeys.map( async (element, i ) => {

                if(element.includes("mp4")){
                    // console.log(`elt`, element)
                    var elt = $(document.createElement('div'));
                    elt.addClass('point');
                    elt.addClass('video')
                } else if(element.includes("jpg")){
                    return;
                } else {
                    var elt = $(document.createElement('div'));
                    elt.addClass('point');
                    elt.addClass('image')
                }

                console.log('element', element, i, `${process.env.REACT_APP_BUCKET_PREFIX}0.png`)

            if( i > 0){

                var rotation = Math.ceil(Math.random() * 2) * (Math.round(Math.random()) ? 1 : -1)
                elt.style = `transform: rotate(${rotation}deg)`
                // elt.addClass('photos_traslucid');
                var pos = spiral(i);

                // Element is Image
                if(element.includes(".png")){

                        var DOM_img = document.createElement("img");
                        DOM_img.style = 'width: 250px; border-radius: 3px;'
                        DOM_img.src = `${basePath}${element}`;
                        DOM_img.id = element;
                        DOM_img.className ='message';
                        await elt.append(DOM_img);

                    // Element is Video
                    } else if ( element.includes(".webm")){
                        var DOM_video = document.createElement("video");
                        var DOM_video_source_webm = document.createElement("source");
                        var DOM_video_source_mp4 = document.createElement("source");

                        DOM_video.style = 'max-width: 250px; width: 250px; -webkit-transform-style: preserve-3d position: absolute; left: 0; z-index: 10;'

                        const mySubStringVideo = element.replace(/\.[^/.]+$/, ".mp4")
                        // const mySubStringThumb = element.replace(/\.[^/.]+$/, ".png")

                        // console.log(`mySubStringVideo`, mySubStringVideo)


                        // new domain https://d320jtv022114v.cloudfront.net/

                        DOM_video_source_webm.src = `${basePath}${element}#t=0.001`;
                        DOM_video_source_mp4.src = `${basePath}${mySubStringVideo}#t=0.001`


                        DOM_video_source_webm.type = 'video/webm'
                        DOM_video_source_mp4.type = 'video/mp4'

                        DOM_video.append(DOM_video_source_webm)
                        DOM_video.append(DOM_video_source_mp4)

                        // old domain https://yama-wall-testing.s3.amazonaws.com/

                        DOM_video.muted = false;
                        DOM_video.loop = true;
                        // DOM_video.preload = 'auto';
                        DOM_video.playsInline = true;

                        await allKeys.map( async (el, i ) => {
                            if(el.includes("jpg")){

                                const mySubStringThumbs = el.substring(
                                    el.indexOf("_") + 1, 
                                    el.lastIndexOf("-")
                                );

                                if(element.includes(mySubStringThumbs)){
                                    // console.log(`tengo`)

                                    // console.log(`element`, mySubStringThumbs)
                                    // console.log(`DOM_video_source_webm/src`, element)

                                    DOM_video.poster = `${basePath}${el}`
                                } 
                            }
                        })

                        

                        // console.log(`arrayThumbs`, arrayThumbs)

                        DOM_video.id = element;
                        DOM_video.className ='video_class';


                        var play_img_div = document.createElement("div")
                        play_img_div.className = 'video_player_div'
                        play_img_div.style = "display: block; z-index: 50; -webkit-transform: translate3d(-50%,-50%,0);";

                        var play_img = document.createElement("img")
                        play_img.src = playVideo
                        play_img.id = element
                        play_img.className ='video_player';
                        play_img.style = "display: block";


                        var videoSign = document.createElement("img")

                        const mySubString = element.substring(
                            element.indexOf("/") + 1, 
                            element.lastIndexOf(".")
                        );

                        console.log('mySubString', mySubString, element.Key)

                        videoSign.src = `${basePath}${process.env.REACT_APP_BUCKET_PREFIX}_${mySubString}_videoSign.png`
                        videoSign.id = videoSign.src
                        videoSign.style = `width: 250px; height: 191px; position: absolute; left: 0; z-index: 100; -webkit-transform: translate3d(0,0,0);`
                        videoSign.className ='video_sign';

                        // console.log(`videoSign.src`, videoSign.src)

                        allVideoSign.push(mySubString)

                        imagesToAppend.push(videoSign)


                        // Create div to be relative to Point
                        // var div_realtive_point = document.createElement("div")

                        if(element.includes("Vertical")){
                            $( DOM_video ).addClass( "vertical" );
                            var play_img_video_vertical = document.createElement("div")
                            play_img_video_vertical.className = 'video_vertical-container'
                            play_img_video_vertical.style = 'position: relative;'

                            await elt.append(play_img_video_vertical);
                            await play_img_video_vertical.append(videoSign);
                            await play_img_video_vertical.append(DOM_video);
                            await play_img_video_vertical.append(play_img_div);
                        } else {

                            await elt.append(videoSign);
                            await elt.append(DOM_video);
                            await elt.append(play_img_div);
                        }

                        
                        
                        await play_img_div.append(play_img);

                    }

                
                    elt.css({
                        left: (( valueScreenWidthLooping / 2 ) - 125 ) + 325 * pos[0],
                        top: (( valueScreenHeightLooping / 2 ) - 125 ) + 280 * pos[1],
                        transform: `rotate(${rotation}deg)`
                    });
                    $('#container').append(elt);
                    // $(elt).addClass( "photos_traslucid" );
                    $(elt).addClass( "hover_zoom" );
                } else {
                    var pos = spiral(0);
                    var DOM_img = document.createElement("img");
                    DOM_img.style = `height: auto`
                    DOM_img.style = `width: 250px`
                    DOM_img.src = `${basePath}${element}`;
                    DOM_img.id = 'logo';
                    DOM_img.className ='wall_logo';
                    await elt.append(DOM_img);
                    // console.log(`valueScreenWidthLooping`, valueScreenWidthLooping)

                    // alert(valueScreenWidthLooping)
                    elt.css({
                        left: (( valueScreenWidthLooping / 2 ) - 125 ) + 400 * pos[0],
                        top:  (( valueScreenHeightLooping / 2 ) - 125 ) + 250 * pos[1],
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    });
                    $(elt).addClass( "logo" );
                    $('#container').append(elt);
                }

                    // $('#container .point')
                    // .delay(1500)
                    // .queue(function (next) { 
                    //     $(this).css('opacity', '1'); 
                    //     console.log(`loop`)
                    // });

                    // $('#container').children().hide()

                    // await $("#container").children().each(function(index) {
                    //     if(index > 0)
                    //       $(this).delay(500*index).fadeIn();
                    //     else
                    //       $(this).show(); //  $(this).fadeIn(); to show immediately 
                    // });
            });



            setTimeout(async () => {
                initListeners()

                await helpers.showThumbs('#container .hover_zoom')
                setDisableParallax(true)

                // await helpers.showElements("#wall", 0, goAfter)

                // function goAfter(){
                //     if(messageUrl){

                //         // console.log(`entro al url mensaje`)
                //         let contentPoints = document.querySelectorAll('.point');
            
                //         contentPoints.forEach(el => {
    
                //             if($(el).children(".video_vertical-container")[0]){
                //                 let verticalEl = $(el)[0].firstElementChild.firstElementChild
    
                //                 // console.log(`VERTICAL`, $(verticalEl).attr("id"))
    
                //                 if($(verticalEl).attr("id").includes(messageUrl)){
                //                     zoomToOwnMessage(el)
                //                 }
                //             } else {
                //                 let element = $(el)[0].firstElementChild
                //                 // console.log(`NORMAL`, $(element).attr("id"))
                //                 if($(element).attr("id").includes(messageUrl)){
                //                     zoomToOwnMessage(el)
                //                 }
                //             }
                //         })  
                //     }
                // }

            }, 2000);

        }


        $( document ).ready( async function() {
    
            function matchVideoSign(){
                // console.log(`allVideoSign`, imagesToAppend)
        
                allVideoSign.forEach(x => {
                    var elems = document.querySelectorAll('.video_class')
                    elems.forEach(video => {
                        if(video.id.includes(x)){
                            // console.log(video.parentNode)
    
                            imagesToAppend.forEach( image => {
                                if(image.id.includes(x)){
                                    video.parentNode.appendChild(image)
                                }
                            })
                        }
                    })
                })
            }
    
            matchVideoSign()

            // Si tengo parametro en Url
    
            // if(singleParam){
            //     zoomTo(singleParam)
            //     // console.log("Tengo params")
            // }
        });
    
        $( window).on("load", function(){
            // alert("load")
        })
          
    }, [messageUrl]);

    // This useEffect is for GSAP animations
    useEffect(() => {

        // gsap.to('#containerWall', { pointerEvents: 'none' })

        // if(  window.location.pathname === '/view' ){
        //     gsap.to('#containerWall', { pointerEvents: 'inherit', delay: 3 } )
        //     gsap.fromTo('#wall img', { opacity: 0, y: 5 }, { opacity: 1, y: 0 })
        //     gsap.fromTo('#wall p', { opacity: 0, y: 5 }, { opacity: 1, y: 0, delay: 1 })
        //     gsap.fromTo('#wall button', { opacity: 0, y: 5 }, { opacity: 1, y: 0, delay: 2 })
        // }

        if(context.endRender){

            setTimeout(() => {
                $('.hover_zoom').addClass('photos_traslucid');


                gsap.fromTo('#wall img', { opacity: 0, y: 5 }, { opacity: 1, y: 0, delay: 0.5  })
                gsap.fromTo('#wall p', { opacity: 0, y: 5 }, { opacity: 1, y: 0, delay: 1 })
                gsap.fromTo('#wall button', { opacity: 0, y: 5 }, { opacity: 1, y: 0, delay: 2 })
                // gsap.to('#containerWall', { pointerEvents: 'inherit', delay: 3 } )
            }, 3000);
        }

        // $('body').css('background-size', 'inherit')

    }, [context.endRender])



    function nextPage(){
        // window.location.href = '/home'
        props.history.push("/submit", { from: 'Wall' } )
        firstTouch = true
    }
    
    function hideObjects(){
        if(firstTouch){
            // console.log(`firstTouch`, firstTouch)
            setDisableParallax(false)
            document.getElementById('hand').style.display = 'none'
            document.getElementById('p').style.display = 'none'
            $('.point').removeClass('photos_traslucid').addClass('photos_animate_point');
        } 
        firstTouch = false
    }

    var isDragging = false;

    const [draggingZoom, setDraggingZoom] = useState(false)

    function initListeners(){

        let currentTransform = valueWall
        // console.log(`initListeners`)

        let div = document.getElementById("zoomCanvas")

        var hammer = new Hammer(div);
        hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL, threshold: 0 }); 


        hammer.on('panmove', () => {
            setTimeout(() => {
                isDragging = true
                setDraggingZoom(true)
            }, 50);
        })

        hammer.on('panend', () => {
            setTimeout(() => {
                isDragging = false
                setDraggingZoom(false)
            }, 50);
        });

        $("#zoomCanvas").on("touchmove", function (event) {

            document.querySelectorAll('video').forEach(vid => vid.pause());
        })

        // $("#zoomCanvas").on("touchend", function (event) {
        //     isDragging = false
        //     setDraggingZoom(false)
        //     // console.log(`isDragging`, isDragging)


        //     // var touch = event.changedTouches[0],
        //     // simulatedEvent = document.createEvent('MouseEvent');

        //     // simulatedEvent.initMouseEvent(
        //     //     { touchstart: 'mousedown', touchmove: 'mousemove', touchend: 'mouseup' } [event.type],
        //     //     true, true, window, 1, 
        //     //     touch.screenX, touch.screenY, touch.clientX, touch.clientY,
        //     //     false, false, false, false, 0, null);

        //     // touch.target.dispatchEvent(simulatedEvent);

        // })

        // let divVideo = document.getElementsByClassName("video_sign")

        // var hammer = new Hammer(divVideo);
        // hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL, threshold: 0 }); 


        $(".video_sign").on("click touchend", async function() {
            if( !isDragging ){

                // alert("videos")
                var el = $(this).closest('.point');

                $(".buttonsControls").hide()

                if($(el).hasClass("zoomed")){
                    // console.log(`TIENE ZOOMED`)
                    closePoint(el)
                    $(el).find('.closeImg')[0].remove()
                    // console.log(`$(this)`,$(el).find('.closeImg')[0].remove())

                    document.querySelectorAll('video').forEach(vid => vid.pause());

                    // Return video to initial time (Check if that is necessary)
                    // Rewind video close

                    // console.log(`object`, $(this).siblings()[0])
                    var siblingVideo = $(this).siblings()[0]
                    $(siblingVideo).get(0).currentTime = 0;


                } else {

                    await afterClick(el, currentTransform)
                    setDisablePan(true)
                    setDisableZoom(true)

                    document.querySelectorAll('video').forEach(vid => vid.pause());

                    // Array.from(document.querySelectorAll(".video_player_div"))
                    //     .forEach(function(val) {
                    //     val.style.display = 'none';
                    // });

                    let player_div = $(this).siblings()[1]
                    

                    player_div.style.display = 'none'

                    console.log('$(this).siblings()', $(this).siblings())

                    var videoSibling1 = $(this).siblings()[0]


                    if (videoSibling1.paused == true) { 
                        videoSibling1.play();
                    }
                }
            }
        });


        $(".message").on("mouseup touchend", async function() {
            if( !isDragging ){
                var el = $(this).closest('.point');

                $(".buttonsControls").hide()

                if($(el).hasClass("zoomed")){
                    // console.log(`TIENE ZOOMED`)
                    closePoint(el)
                    $(el).find('.closeImg')[0].remove()
                    // console.log(`$(this)`,$(el).find('.closeImg')[0].remove())

                } else {

                    await afterClick(el, currentTransform)
                    setDisablePan(true)
                    setDisableZoom(true)

                    document.querySelectorAll('video').forEach(vid => vid.pause());
                }

            }
        });
    }


    function closePoint(el){

        $(".buttonsControls").show()

        $(".video_player_div").show()

        $(el).addClass('hover_zoom')
        document.querySelectorAll('video').forEach(vid => vid.pause());



        document.querySelectorAll('.point').forEach(pt => $(pt).removeClass("zoomed") );
        
        // $(imgClose).remove();

        let posEl = document.getElementById("zoomCanvas")
        posEl.style.transform = currentPos

        

        // $( "body" ).css('background-size', '100%');

        // console.log(`currentPos`, currentPos)
        // $( "#zoomCanvas" ).css('transform', 'translate(' + value.translation.x +'px,'+ value.translation.y +'px) scale(1)');
        // setValue(value)
        setDisablePan(false)
        setDisableZoom(false)
        setDisableParallax(false)

        firstSelectedPoint = true

        $('.point').removeClass( "photos_traslucid")
    }


    function afterClick(el, currentTransform){

        document.querySelectorAll('.point').forEach(pt => $(pt).removeClass("zoomed") );

        $(el).removeClass('hover_zoom')
        
        setDisableParallax(true)

        var hash = require('object-hash');

            $(".closeImg").remove();
            let arrPoint = $(el)[0]
            // window.clearInterval(window.interval);

            // console.log(arrPoint.firstChild.src)

            if(firstSelectedPoint == true){
                currentPos = document.getElementById("zoomCanvas").style.transform
                // console.log(`currentPos`, currentPos)
            }

            firstSelectedPoint = false
            

            if(arrPoint.firstChild.className === 'wall_logo'){
                return null;
            }else{
                $('.point').addClass( "photos_traslucid" );
                $('.point').removeClass( "photos_animate_point")
                $(el).removeClass( "photos_traslucid")
                
                let closeImg = document.createElement("img");
                closeImg.src = closeImgSvg;
                closeImg.className ='closeImg';
                closeImg.style.display = 'block';
                $(closeImg).on("click touchstart", async function() {
                    closePoint()
                    $(this).remove();
                    $(el).addClass('hover_zoom')
                })

                $(el).append(closeImg);


                // let my_hash = hash(arrPoint.firstChild.id)
                // props.history.push(`/view?message=${my_hash}`)

                // console.log(`currentTransform`, currentTransform)

                // FORMULA
                let point0 = $('.wall_logo').parent()[0]

                // console.log(`point0`, point0)

                let left0 = parseInt(point0.style.left)
                let top0 = parseInt(point0.style.top)


                // console.log(`left0`, left0)
                // console.log(`top0`, top0)

                let valuePointLeft = parseInt(arrPoint.style.left);
                let valuePointTop = parseInt(arrPoint.style.top);
                let valueScreenWidth = window.innerWidth;
                let valueScreenHeight = window.innerHeight;


                let imgW = arrPoint.firstChild.clientWidth;
                let imgH = arrPoint.firstChild.clientHeight;


                // console.log(`valuePointLeft`, valuePointLeft)
                // console.log(`valueScreenWidth`, valueScreenWidth)
                // console.log(`imgW`, imgW)
                // console.log(`imgH`, imgH)

                // let pedalScale

                // if(valueScreenWidth < 560){
                //     pedalScale = 1.1
                // } else {
                //     pedalScale = 2
                // }

                //     let setScale = (valueScreenWidth * pedalScale) / 320

                //     let getLeft = (left0 - valuePointLeft) * pedalScale
                //     let getTop =  (top0 - valuePointTop) * pedalScale
                //     $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+pedalScale+')');
                //     setValue({scale: pedalScale, translation: { x: getLeft, y: getTop } })
                //     console.log(`getLeft`, getLeft)

                //     console.log(`getTop`, getTop)


                if(valueScreenWidth < 560 && valueScreenWidth > 320 ){
                    let pedalScale = 1.05
                    let setScale = (valueScreenWidth * pedalScale) / 320

                    let getLeft = (   ( ( valuePointLeft * setScale ) - ( valueScreenWidth / 2 ) )   +   ( imgW  / setScale)  ) * (-1);
                    let getTop =  (   ( ( valuePointTop * setScale ) - ( valueScreenHeight/ 2 ) )   +    ( imgH / setScale )  ) * (-1);
                    $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
                    // setValue({scale: setScale, translation: { x: getLeft, y: getTop } })

                } else  if(valueScreenWidth <= 320) {

                    let setScale = 1.2

                    let getLeft = (   ( ( valuePointLeft * 1.2 ) - ( valueScreenWidth / 2 ) )   +   ( imgW / 1.65 )  ) * (-1);
                    let getTop =  (   ( ( valuePointTop * 1.2 ) - ( valueScreenHeight / 2 ) )   +    ( imgH / 1.2 )  ) * (-1);
                    $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
                    // setValue({scale: setScale, translation: { x: getLeft, y: getTop } })
                    
                } else {
                    let getLeft = (   ( ( valuePointLeft * 2 ) - ( valueScreenWidth / 2 ) )   +    imgW  ) * (-1);
                    let getTop =  (   ( ( valuePointTop * 2 ) - ( valueScreenHeight/ 2 ) )   +    imgH  ) * (-1);
                    $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale(2)');
                    // setValue({scale: 2, translation: { x: getLeft, y: getTop } })
                }

                $(el).addClass("zoomed")
            }
    }

    function zoomTo(element){

        var hash2 = require('object-hash');

        let content = document.querySelectorAll('.point');
        let myChilds = []
        let ids = []

        content.forEach(el => {
            myChilds.push(el.firstElementChild)
        })

        myChilds.forEach(child => {
            ids.push($(child).attr("id"))
        });
 

        ids.forEach(id =>{
            if(hash2(`${id}`) === element){

                const myElement = document.getElementById(id).parentElement
                // console.log("TO...", myElement)

                $('.point').addClass( "photos_traslucid" );
                $('.point').removeClass( "photos_animate_point")
                $(myElement).removeClass( "photos_traslucid")


                $(myElement).click();

                if($(myElement).children().first()[0].className == "video_class"){

                    Array.from(document.querySelectorAll(".video_player_div"))
                        .forEach(function(val) {
                        val.style.display = 'none';
                    });

                    let myVideo2 =  $(myElement).children().first()[0]
                    // console.log("myVideo2", myVideo2)
                    if (myVideo2.paused == true) {
                        myVideo2.muted = true
                        myVideo2.play();
                    }
                
                }

                // document.getElementById(id).click();
                
                let closeImg = document.createElement("img");
                closeImg.src = closeImgSvg;
                closeImg.className ='closeImg';
                closeImg.style.display = 'block';
                $(myElement).append(closeImg);


                // // FORMULA
                let valuePointLeft = parseInt(myElement.style.left);
                let valuePointTop = parseInt(myElement.style.top);
                let valueScreenWidth = window.innerWidth;
                let valueScreenHeight = window.innerHeight;
                let imgW = myElement.firstChild.clientWidth;
                let imgH = myElement.firstChild.clientHeight;
                // let getLeft =   (valueScreenWidth / 2) - (valuePointLeft - ( imgW / 2));
                // let getTop =    (valueScreenHeight / 2) - (valuePointTop - ( imgH / 2));
                let getLeft = (   ( ( valuePointLeft * 2 ) - ( valueScreenWidth / 2 ) )   +    imgW  ) * (-1);
                let getTop =  (   ( ( valuePointTop * 2 ) - ( valueScreenHeight/ 2 ) )   +    imgH  ) * (-1);
                
                $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale(2)');
                setValue({scale: 2, translation: { x: getLeft, y: getTop } })

                // setValue({scale: null, translation: { x: null, y: null } })

            }
        })
    }


    function zoomToOwnMessage(id){

        if(firstSelectedPoint == true){
            currentPos = document.getElementById("zoomCanvas").style.transform
            // console.log(`currentPos`, currentPos)
        }

        firstSelectedPoint = false

        let arrPoint = $(id)[0]

        $('.point').addClass( "photos_traslucid" );
        $('.point').removeClass( "photos_animate_point")
        $(id).removeClass( "photos_traslucid")

        setDisablePan(true)
        setDisableZoom(true)
        
        let closeImg = document.createElement("img");
        closeImg.src = closeImgSvg;
        closeImg.className ='closeImg';
        closeImg.style.display = 'block';
        $(closeImg).on("click touchstart", async function() {
            $(".buttonsControls").show()

            $(this).remove();
            
            $(".video_player_div").show()
            document.querySelectorAll('video').forEach(vid => vid.pause());
            

            let posEl = document.getElementById("zoomCanvas")
            posEl.style.transform = currentPos

            // $( "body" ).css('background-size', '100%');

            // console.log(`currentPos`, currentPos)
            // $( "#zoomCanvas" ).css('transform', 'translate(' + value.translation.x +'px,'+ value.translation.y +'px) scale(1)');
            // setValue(value)
            setDisablePan(false)
            setDisableZoom(false)

            firstSelectedPoint = true

            $('.point').removeClass( "photos_traslucid")
        })

        $(id).append(closeImg);


        // let my_hash = hash(arrPoint.firstChild.id)
        // props.history.push(`/view?message=${my_hash}`)

        // console.log(`currentTransform`, currentTransform)

        // FORMULA
        let point0 = $('.wall_logo').parent()[0]

        // console.log(`point0`, point0)

        let left0 = parseInt(point0.style.left)
        let top0 = parseInt(point0.style.top)


        // console.log(`left0`, left0)
        // console.log(`top0`, top0)

        let valuePointLeft = parseInt(arrPoint.style.left);
        let valuePointTop = parseInt(arrPoint.style.top);
        let valueScreenWidth = window.innerWidth;
        let valueScreenHeight = window.innerHeight;


        let imgW = arrPoint.firstChild.clientWidth;
        let imgH = arrPoint.firstChild.clientHeight;


        // console.log(`valuePointLeft`, valuePointLeft)
        // console.log(`valueScreenWidth`, valueScreenWidth)
        // console.log(`imgW`, imgW)
        // console.log(`imgH`, imgH)

        // let pedalScale

        // if(valueScreenWidth < 560){
        //     pedalScale = 1.1
        // } else {
        //     pedalScale = 2
        // }

        //     let setScale = (valueScreenWidth * pedalScale) / 320

        //     let getLeft = (left0 - valuePointLeft) * pedalScale
        //     let getTop =  (top0 - valuePointTop) * pedalScale
        //     $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+pedalScale+')');
        //     setValue({scale: pedalScale, translation: { x: getLeft, y: getTop } })
        //     console.log(`getLeft`, getLeft)

        //     console.log(`getTop`, getTop)


        if(valueScreenWidth < 560 && valueScreenWidth > 320 ){
            let pedalScale = 1.14
            let setScale = (valueScreenWidth * pedalScale) / 320

            let getLeft = (   ( ( valuePointLeft * setScale ) - ( valueScreenWidth / 2 ) )   +   ( imgW  / setScale)  ) * (-1);
            let getTop =  (   ( ( valuePointTop * setScale ) - ( valueScreenHeight/ 2 ) )   +    ( imgH / setScale )  ) * (-1);
            $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
            localStorage.setItem('messageUrl', '');
            // setValue({scale: setScale, translation: { x: getLeft, y: getTop } })

        } else  if(valueScreenWidth <= 320) {

            let setScale = 1.2

            let getLeft = (   ( ( valuePointLeft * 1.2 ) - ( valueScreenWidth / 2 ) )   +   ( imgW / 1.65 )  ) * (-1);
            let getTop =  (   ( ( valuePointTop * 1.2 ) - ( valueScreenHeight / 2 ) )   +    ( imgH / 1.2 )  ) * (-1);
            $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
            localStorage.setItem('messageUrl', '');
            // setValue({scale: setScale, translation: { x: getLeft, y: getTop } })
            
        } else {
            let getLeft = (   ( ( valuePointLeft * 2 ) - ( valueScreenWidth / 2 ) )   +    imgW  ) * (-1);
            let getTop =  (   ( ( valuePointTop * 2 ) - ( valueScreenHeight/ 2 ) )   +    imgH  ) * (-1);
            $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale(2)');
            localStorage.setItem('messageUrl', '');
            // setValue({scale: 2, translation: { x: getLeft, y: getTop } })
        }
    }

    function handleChange(event) {
        setValue(event)
    }

    useEffect(() => {
        context.setRenderDisplay(true)
    }, [])

    return (    
    <>
        <div className={styles.load_container}>
            <div className={styles.load_screen} ref={(el) => (screen = el)}>
            </div>
        </div>


        <div id="containerWall">
            {/* <img id="containerWallImg" src="/assets/1mm_background.webp"/> */}

            {/* <div class="slide one"></div> */}

            <div id="zoomContainer" className={[styles.general_container, "zoomContainer"].join(' ')}  onTouchStart={hideObjects} onMouseDown={() => {hideObjects()}} > 
                <MapInteractionCSS id={'mapInteraction'} draggingZoom={draggingZoom} disableParallax={disableParallax} disablePan={disablePan} disableZoom={disableZoom} onChange={(v) =>{ handleChange(v) }} >
                    <div id="container" >

                    </div>
                </MapInteractionCSS>

                <div id="wall">
                    <div id='hand' className={[styles.swip_container, "anim"].join(' ')} >
                        <img className={styles.swip_hand} src={hand_move} alt="" />
                    </div>
                    <div id='p' className={[styles.p_container, "anim"].join(' ')}>
                        <p className={styles.swip_p}  >Drag to see what others wrote</p>
                    </div>

                    <div id='button' className={[styles.button_container, "anim"].join(' ')}>
                        <button type='button' className={styles.buttonContainerFoot} onClick={() => {nextPage()}}>Leave your message here<FontAwesomeIcon icon={faPaperPlane} className={styles.send_plane}/> </button>
                    </div>
                </div>
                

                <div id='footer' className={styles.footer}>
                    <p className={styles.swip_footer} ><img src={footer} alt="" /></p>
                </div>

                {/* {props.location.pathname !== '/view' && props.location.pathname !== '/home' && props.location.pathname !== '/' ?  <div  ref={elWallBg} id='backgroundWall' className={styles.bg}> </div>: null} */}
            </div>

        </div>
    </>
    );
}
export default Wall;