import React, { useContext } from 'react';
import { CanvasContext } from '../../UserContext'

import styles from '../css/canvas.module.css';

import undo_draw from '../../assets/undoDrawing.svg';

function CanvasUndo(props){

    const context = useContext(CanvasContext)

    async function undo(){
        // document.getElementById('empty_container').style.display = 'none'

        context.canvas2.discardActiveObject().renderAll();

        let imgInCanvas = false;

        await context.canvas2.getObjects().forEach( function(o) {
            if(o.id == 'fabric_js') {
                imgInCanvas = true
            }
        })

      if(imgInCanvas){

        if(context.canvas2._objects.length>2){
            context.h.push(context.canvas2._objects.pop());
            context.canvas2.renderAll();
        }
        
      } else {
        if(context.canvas2._objects.length>1){
            context.h.push(context.canvas2._objects.pop());
            context.canvas2.renderAll();
        }
      }
    }

return (
    <>
        <button onClick={undo} className={styles.select_button}>
            <img className={styles.select_undo} src={undo_draw} />
            <p className={styles.drawing_p_undo}>Undo</p>
            {/* <span className='tooltip_text'>Undo</span> */}
        </button>
    </>
    )
}

export default CanvasUndo;