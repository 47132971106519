import React, { useEffect , useState, useRef} from 'react';
import './css/View.css';
import $ from "jquery"

// import QRCode from "react-qr-code";

import  MapInteractionCSS  from './Zoom';
import styles from './css/falsewall.module.css';
import hand_move from '../assets/hand_move.svg';
import footer from '../assets/footer-powered-by.svg'
import closeImgSvg from '../assets/closeWall.svg';
import playVideo from "../assets/play.svg"
// import notched from '../assets/Notch-Takeda-wall.svg'

// import videoWebm from '../assets/intro_onconnect.webm'
// import video from '../assets/intro2.mov'

// import qr from '../assets/qr_submit2.png'

import { gsap, Power3, Power4 } from "gsap";

import { faIgloo, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import helpers from './Helpers/Anims';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ScaleContext } from '../UserContext';

// import particles from '../assets/particles.png'

// import Particles from "react-tsparticles"; 
// import { loadFull } from "tsparticles"; 

var allKeys = []
const allVideoSign = []
const imagesToAppend = []

var firstTouch = true;

var loadedImages = 0

var basePath = process.env.REACT_APP_S3_URL;
var cdn = "https://d138rfrd863xsn.cloudfront.net/"

var useCdn = false

if(useCdn){
  basePath = cdn
}

gsap.ticker.lagSmoothing(false);


// var particles = "https://d15hqu33htm3jb.cloudfront.net/assets/venture2023/background_particles_2023_2.png"


var firstSelectedPoint = true

var currentPos;

var orientation;

var posClick = { x: '', y: ''}


var array2Length

function Display(props){

    const [slideShowActivate, setSlideShowActivate] = useState(false)

    const [renderNew, setRenderNew] = useState(false)

    const [loadedImagesDOM, setLoadedImagesDOM] = useState(false)
    const [startDisplay, setStartDisplay] = useState(false)

    const [anims, setAnims] = useState(false)

    const [left, setLeft] = useState(0)
    const [top, setTop] = useState(0)

    const context = React.useContext(ScaleContext)

    var screen = context.screen
    var body = context.body


    const queryParams = new URLSearchParams(props.history.location.search)
    const singleParam = queryParams.get('message')


    let messageUrl;
    let sentMessage;

    var array1Length;
    
    let arrayLeft = [
        835,
        1160,
        1160,
        1160,
        835,
        510,
        510,
        510,
        835,
        835,
        1160,
        1485,
        1485,
        1485,
        1485,
        510,
        185,
        835,
        510,
        185,
        185,
        185,
        185,
        185,
        510,
        1810,
        -135,
        1810,
        -135,
        -135,
        1810,
        -135,
        1810,
        -135,
        1810
    ]

    let arrayTop =  [
        360,
        80,
        360,
        610,
        610,
        610,
        360,
        80,
        80,
        860,
        860,
        860,
        80,
        360,
        610,
        860,
        80,
        860,
        860,
        860,
        610,
        360,
        80,
        860,
        860,
        365,
        365,
        80,
        80,
        610,
        610,
        860,
        860,
        860,
        860
    ]

    var cantidadMessages

    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)

    const [firstKeyCount, setFirstKeyCount] = useState(0)
    const [secondKeyCount, setSecondKeyCount] = useState(0)

    const [mousePos, setMousePos] = useState({});
    // const [posClick, setPosClick] = useState({  x: '', y: ''});
    

    useEffect(() => {

        $('body').addClass( "display" );

      const handleMouseMove = (event) => {
        setMousePos({ x: event.clientX, y: event.clientY });
      };
  
      window.addEventListener('click', handleMouseMove);
  
      return () => {
        window.removeEventListener(
          'click',
          handleMouseMove
        );
      };
    }, []);

  
    useEffect(() => {
  
      
  
      // Switch by Width
      switch (true) {
        case  mousePos.x < (window.innerWidth / 3 ):
            posClick.x = 'left'
            // setPosClick( prev => ({ ...prev, x: 'left' }))
        break;
  
        case  ( ( mousePos.x > (window.innerWidth / 3 ) ) && (mousePos.x < ( window.innerWidth / 3 * 2)) ):
            posClick.x = 'middle'
            // setPosClick( prev => ({ ...prev, x: 'middle' }))
        break;
  
        case  ( ( mousePos.x > (window.innerWidth / 3  * 2 ) ) && (mousePos.x < ( window.innerWidth / 3 * 3)) ):
            posClick.x = 'rigth'
            // setPosClick( prev => ({ ...prev, x: 'rigth' }))
        break;
      
        default: 
          break;
      }
  
  
      // Switch by Height
      switch (true) {
        case  mousePos.y < (window.innerHeight / 3 ):
            posClick.y = 'top'
            // setPosClick( prev => ({ ...prev, y: 'top' }))
        break;
  
        case  ( ( mousePos.y > (window.innerHeight / 3 ) ) && (mousePos.y < ( window.innerHeight / 3 * 2)) ):
            posClick.y = 'middle'
            // setPosClick( prev => ({ ...prev, y: 'middle' }))
        break;
  
        case  ( ( mousePos.y >= (window.innerHeight / 3 * 2) ) && (mousePos.y <= ( window.innerHeight / 3 * 3)) ):
            posClick.y = 'bottom'
            // setPosClick( prev => ({ ...prev, y: 'bottom' }))
        break;
  
      
        default: 
          break;
      }
  
  
    }, [mousePos])


    useEffect(() => {
        // console.log('posClick', posClick)

        // backgroundAnimationZoomIn()
        // backgroundAnimationZoomInResponsive()
        // backgroundAnimationZoomOut()
        // backgroundAnimationZoomOutResponsive()

    }, [posClick])
    

    function backgroundAnimationZoomIn(){
        
        console.log('backgroundAnimationZoomIn', posClick)
        $("#containerWallImg").css('width', '140%')
    
        // switch (true) {
        //     case posClick.x == 'left':
        //             $("#containerWallImg").css({ 'width': '140%' , 'transform': 'translate( -20px , -4px )'})
        //         break;
        
        //     default:
        //         break;
        // }
    }
    
    function backgroundAnimationZoomInResponsive(){
        $("#containerWallImg").css('height', '120%')
    }
    
    function backgroundAnimationZoomOut(){
        $("#containerWallImg").css({'width': '120%', 'transform' : 'inherit'})
    }
    
    function backgroundAnimationZoomOutResponsive(){
        $("#containerWallImg").css('height', '110%')
    }



    useEffect(() => {


        if (window.matchMedia("(orientation: portrait)").matches) {
            orientation = "portrait"
        }
            
        if (window.matchMedia("(orientation: landscape)").matches) {
            orientation = "landscape"
        }


        // context.setRender(false)

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)

                if(props.location.pathname === '/view'){

                    window.location.reload();
                }
            }, false);
        }
    
    }, [onRotate])


    const [valueWall, setValue] = useState({
        scale: 1,
        translation: { x: 0, y: 0 }
    });


      const [disablePan, setDisablePan] = useState(false)
      const [disableZoom, setDisableZoom] = useState(false)

      const [disableParallax, setDisableParallax] = useState(true)
      

      const AWS = require('aws-sdk')
      AWS.config.update({
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACESS_KEY,
          region: 'us-east-1',
          acl: 'public-read'
      })

      const s3 = new AWS.S3();
      var params = {
        Bucket: process.env.REACT_APP_BUCKET,
        Prefix: process.env.REACT_APP_BUCKET_PREFIX
      };


      const tlIntro = useRef();


      useEffect(() => {
            context.setRenderDisplay(true)
      }, [])


    allKeys = []

    useEffect( () => {


        setSecondKeyCount(0)

        const getKeys = async() => {

            s3.listObjectsV2(params, async function (err, data) {

                const sortedObjects = data.Contents.sort((a, b) => b.LastModified - a.LastModified);
                array1Length = data.Contents.length
                // console.log("Array 1", array1Length)
    
                cantidadMessages = data.Contents.length
                setFirstKeyCount(data.KeyCount)
                
                await sortedObjects.map( (element) => {
    
                    console.log('element', element)
                    if( element.Key.includes(".mp4") || element.Key.includes("videoSign")){
    
                    } else if ( element.Key !== process.env.REACT_APP_BUCKET_PREFIX ) {
                        allKeys.push(element.Key)
                    }
                    
                });
                Looping();
            })
        }

        

        getKeys()

        const spiral = (n) => {
        // given n an index in the squared spiral
        // p the sum of point in inner squa re
        // a the position on the current square
        // n = p + a

        var r = Math.floor((Math.sqrt(n + 0) - 1) / 2) + 1;

        // compute radius : inverse arithmetic sum of 8+16+24+...=
        var p = (8 * r * (r - 1)) / 2;
        // compute total point on radius -1 : arithmetic sum of 8+16+24+...

        var en = r * 2;
        // points by face

        var a = (1 + n - p) % (r * 8);
        // compute de position and shift it so the first is (-r,-r) but (-r+1,-r)
        // so square can connect

        var pos = [0, 0, r];
            // eslint-disable-next-line default-case
            switch (Math.floor(a / (r * 2))) {
                // find the face : 0 top, 1 right, 2, bottom, 3 left 
                case 0:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = a - r;
                        pos[1] = -r;
                    }
                    break;
                case 1:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = r;
                        pos[1] = (a % en) - r;

                    }
                    break;
                case 2:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = r - (a % en);
                        pos[1] = r;
                    }
                    break;
                case 3:
                    // eslint-disable-next-line no-lone-blocks
                    {
                        pos[0] = -r;
                        pos[1] = r - (a % en);
                    }
                    break;
            }
            return pos;
        }


        const imagesLoading = () => {
            // console.log('loading')
            loadedImages ++


            if(array1Length > 1){
                // console.log('array1Length', loadedImages, array1Length)

                if(loadedImages ==  array1Length - 1){
                    // console.log('loadedImages', loadedImages, array1Length)
    
                    setLoadedImagesDOM(true)
                    // context.setEndRender(true)
                }
            } else {
                setLoadedImagesDOM(true)
                // console.log('array1Length en 1', array1Length)
            }
            
        }
        
        const Looping = async() => {
            // Create each point of spiral

            $('#container').empty()
            
            // allKeys.reverse()
            // allKeys.unshift(allKeys.pop());
           
            let valueScreenWidthLooping = window.innerWidth;
            let valueScreenHeightLooping = window.innerHeight;


            let arrayThumbs = []


            const index0 = await allKeys.findIndex(element0 => element0 === `${process.env.REACT_APP_BUCKET_PREFIX}0.png`);
            if (index0 !== -1) {
                // Extraer el elemento encontrado
                const foundElement = allKeys.splice(index0, 1)[0];
            
                // Mover el elemento al inicio del array
                allKeys.unshift(foundElement);
            }


            // if(array1Length <= 32){

            //     $( "#zoomCanvas" ).css({'transform': 'scale(0.9)', 'transition': `all 1s ease-out 0s`, 'transform-origin': 'center'});
            // } 


            await allKeys.map( async (element, i ) => {

                console.log('array1Length', array1Length)

                if(element.includes("mp4")){
                    var elt = $(document.createElement('div'));
                    elt.addClass('point');
                    elt.addClass('video')
                } else if(element.includes("jpg")){
                    var elt = $(document.createElement('div'));
                    elt.addClass('point');
                    elt.addClass('video')
                } else {
                    var elt = $(document.createElement('div'));
                    elt.addClass('point');
                }

            if( i > 0){

                var rotation = Math.ceil(Math.random() * 2) * (Math.round(Math.random()) ? 1 : -1)
                // console.log('rotation', rotation)


                var offsetPointx = Math.ceil(Math.random() * 8) * (Math.round(Math.random()) ? 1 : -1)
                var offsetPointy = Math.ceil(Math.random() * 8) * (Math.round(Math.random()) ? 1 : -1)


                elt.style = `transform: rotate(${rotation}deg)`

                // if(array1Length <= 17){
                //     var pos = i
                // } else {
                    var pos = spiral(i);
                // }

                elt.addClass('image')

                // Element is Image
                if(element.includes(".png") || element.includes("jpg")){

                    var DOM_img = document.createElement("img");

                    DOM_img.addEventListener('load', imagesLoading )
                    DOM_img.style = 'width: 250px; border-radius: 3px; '
                    DOM_img.src = `${basePath}${element}`;
                    DOM_img.id = element;
                    DOM_img.className ='message';
                    await elt.append(DOM_img);

                    // Element is Video
                    } else if ( element.includes(".webm")){
                        var DOM_video = document.createElement("video");
                        var DOM_video_source_webm = document.createElement("source");
                        var DOM_video_source_mp4 = document.createElement("source");

                        DOM_video.style = 'max-width: 250px; width: 250px; -webkit-transform-style: preserve-3d position: absolute; left: 0; z-index: 10;'

                        const mySubStringVideo = element.replace(/\.[^/.]+$/, ".mp4")

                        DOM_video_source_webm.src = `${basePath}${element}#t=0.001`;
                        DOM_video_source_mp4.src = `${basePath}${mySubStringVideo}#t=0.001`


                        DOM_video_source_webm.type = 'video/webm'
                        DOM_video_source_mp4.type = 'video/mp4'

                        DOM_video.append(DOM_video_source_webm)
                        DOM_video.append(DOM_video_source_mp4)


                        DOM_video.muted = false;
                        DOM_video.loop = true;
                        // DOM_video.preload = 'auto';
                        DOM_video.playsInline = false;

                        await allKeys.map( async (el, i ) => {
                            if(el.includes("jpg")){

                                const mySubStringThumbs = el.substring(
                                    el.indexOf("_") + 1, 
                                    el.lastIndexOf("-")
                                );

                                if(element.includes(mySubStringThumbs)){

                                    DOM_video.poster = `${basePath}${el}`
                                }

                                
                            }
                            
                        })


                        DOM_video.id = element;
                        DOM_video.className ='video_class';


                        var play_img_div = document.createElement("div")
                        play_img_div.className = 'video_player_div'
                        play_img_div.style = "display: block; z-index: 50; -webkit-transform: translate3d(-50%,-50%,0);";

                        var play_img = document.createElement("img")
                        play_img.src = playVideo
                        play_img.id = element
                        play_img.className ='video_player';
                        play_img.style = "display: block";


                        var videoSign = document.createElement("img")

                        const mySubString = element.substring(
                            element.indexOf("/") + 1, 
                            element.lastIndexOf(".")
                        );

                        videoSign.src = `${basePath}${process.env.REACT_APP_BUCKET_PREFIX}_${mySubString}_videoSign.png`
                        videoSign.id = videoSign.src
                        videoSign.style = `width: 250px; height: 191px; position: absolute; left: 0; z-index: 100; -webkit-transform: translate3d(0,0,0);`
                        videoSign.className ='video_sign';

                        allVideoSign.push(mySubString)

                        imagesToAppend.push(videoSign)


                        // Create div to be relative to Point
                        // var div_realtive_point = document.createElement("div")

                        if(element.includes("Vertical")){
                            $( DOM_video ).addClass( "vertical" );
                            var play_img_video_vertical = document.createElement("div")
                            play_img_video_vertical.className = 'video_vertical-container'
                            play_img_video_vertical.style = 'position: relative;'

                            await elt.append(play_img_video_vertical);
                            await play_img_video_vertical.append(videoSign);
                            await play_img_video_vertical.append(DOM_video);
                        } else {

                            await elt.append(videoSign);
                            await elt.append(DOM_video);
                        }

                        
                        await elt.append(play_img_div);
                        await play_img_div.append(play_img);

                    }


                    // if(array1Length <= 17){
                        
                    //     elt.css({
                    //         display: 'flex',
                    //         left:  arrayLeft[i] ,
                    //         top:  arrayTop[i],
                    //         transform: `rotate(${rotation}deg)`
                    //     });
                    // } else {
                        elt.css({
                            left: (( valueScreenWidthLooping / 2 ) - 125 ) + 325 * pos[0],
                            top: (( valueScreenHeightLooping / 2 ) - 125 ) + 280 * pos[1],
                            transform: `rotate(${rotation}deg)`
                        });
                    // }
                    
                

                    $('#container').append(elt);
                    // $(elt).addClass( "photos_traslucid" );
                    $(elt).addClass( "hover_zoom" );
                } else {
                    // if(array1Length > 17){

                        var pos = spiral(i);
                    // }
                    var DOM_img = document.createElement("img");

                    DOM_img.addEventListener('load', imagesLoading )
                    DOM_img.style = `height: auto`
                    DOM_img.style = `width: 250px`
                    DOM_img.src = `${basePath}${element}`;
                    DOM_img.id = 'logo';
                    DOM_img.className ='wall_logo';
                    await elt.append(DOM_img);
                    // console.log(`valueScreenWidthLooping`, valueScreenWidthLooping)

                    // alert(valueScreenWidthLooping)

                    // if(array1Length <= 17){
                    //     elt.css({
                    //         left:835,
                    //         top:  360,
                    //         background: 'none',
                    //         border: 'none',
                    //         boxShadow: 'none',
                    //         display: 'flex',
                    //         justifyContent: 'center',
                    //         alignItems: 'center'
                    //     });
                    // }else {
                        elt.css({
                            left: (( valueScreenWidthLooping / 2 ) - 125 ) + 400 * pos[0],
                            top:  (( valueScreenHeightLooping / 2 ) - 125 ) + 250 * pos[1],
                            background: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        });
                    // }


                    $(elt).addClass( "logo" );
                    $('#container').append(elt);
                }

                    // $('#container .point')
                    // .delay(1500)
                    // .queue(function (next) { 
                    //     $(this).css('opacity', '1'); 
                    //     console.log(`loop`)
                    // });

                    // $('#container').children().hide()

                    // await $("#container").children().each(function(index) {
                    //     if(index > 0)
                    //       $(this).delay(500*index).fadeIn();
                    //     else
                    //       $(this).show(); //  $(this).fadeIn(); to show immediately 
                    // });
            });

            setLoadedImagesDOM(true)

            setTimeout(async () => {
                initListeners()

                // await helpers.showThumbs('#container .hover_zoom')
                gsap.fromTo('#containerWall .wall_logo', { opacity: 0, y: 2 }, { opacity: 1, y: 0 })
                setDisableParallax(true)

                // await helpers.showElements("#wall", 0, goAfter)

                function goAfter(){
                    if(messageUrl){

                        let contentPoints = document.querySelectorAll('.point');
            
                        contentPoints.forEach(el => {
    
                            if($(el).children(".video_vertical-container")[0]){
                                let verticalEl = $(el)[0].firstElementChild.firstElementChild
    
                                // console.log(`VERTICAL`, $(verticalEl).attr("id"))
    
                                if($(verticalEl).attr("id").includes(messageUrl)){
                                    zoomToOwnMessage(el)
                                }
                            } else {
                                let element = $(el)[0].firstElementChild
                                // console.log(`NORMAL`, $(element).attr("id"))
                                if($(element).attr("id").includes(messageUrl)){
                                    zoomToOwnMessage(el)
                                }
                            }
                        })  
                    }
                }

                

            }, 1000);

        }

        return () => {
            // $(".points").emtpy()
            // getKeys();
            // spiral();
            // imagesLoading();
        }

    }, [renderNew]);


    const elWallBtn = useRef();
    const tlWall = useRef();
    const elWallText = useRef();
    const elWallIcon = useRef();
    const elWallBg = useRef();
    // This useEffect is for GSAP animations


    const [firstEndRender, setfirstEndRender] = useState(false)

    useEffect(() => {

        console.log('context.loadedImagesDOM ', loadedImagesDOM, firstEndRender )

        if( !firstEndRender && context.endRender){

            setfirstEndRender(true)
            $('#container .point').removeClass('photos_traslucid')
            $('#container .point').css( "opacity", "0" );

            // console.log('context.renderDisplay ', context.renderDisplay )

            setTimeout(() => {

                $('#container .image').addClass( "photos_traslucid" )
                
                setSlideShowActivate(true)
                modePresentation()

                context.setEndRender(false)
                context.setRenderDisplay(false)

                loadedImages = 0

            }, 8000);

        }

        // $('body').css('background-size', 'inherit')

    }, [loadedImagesDOM, context.endRender, firstEndRender])
    

    function nextPage(){
        // window.location.href = '/home'
        props.history.push("/user", { from: 'Wall' } )
        firstTouch = true
    }
    
    function hideObjects(){
        if(firstTouch){
            setDisableParallax(false)
            document.getElementById('hand').style.display = 'none'
            document.getElementById('p').style.display = 'none'
            $('.point').removeClass('photos_traslucid').addClass('photos_animate_point');
        } 
        firstTouch = false
    }

    var isDragging = false;

    const [draggingZoom, setDraggingZoom] = useState(false)

    function initListeners(){

        let currentTransform = valueWall

        $("#zoomCanvas").on("touchmove", function () {
            isDragging = true
            setDraggingZoom(true)
        })

        $("#zoomCanvas").on("touchend", function () {
            isDragging = false
            setDraggingZoom(false)
        })


        $(".video_sign").on("click", async function() {
            if( !isDragging ){

                var el = $(this).closest('.point');

                $(".buttonsControls").hide()

                if($(el).hasClass("zoomed")){
                    // console.log(`TIENE ZOOMED`)
                    closePoint(el)
                    $(el).find('.closeImg')[0].remove()

                    document.querySelectorAll('video').forEach(vid => vid.pause());

                    // Return video to initial time (Check if that is necessary)
                    // Rewind video close

                    var siblingVideo = $(this).siblings()[0]
                    $(siblingVideo).get(0).currentTime = 0;


                } else {

                    await afterClick(el, currentTransform)
                    setDisablePan(true)
                    setDisableZoom(true)


                    Array.from(document.querySelectorAll(".video_player_div"))
                        .forEach(function(val) {
                        val.style.display = 'none';
                    });

                    var videoSibling1 = $(this).siblings()[0]


                    if (videoSibling1.paused == true) { 
                        videoSibling1.play();
                    }
                }
            }
        });


        $(".message").on("click", async function() {
            if( !isDragging ){
                var el = $(this).closest('.point');

                $(".buttonsControls").hide()

                if($(el).hasClass("zoomed")){
                    // console.log(`TIENE ZOOMED`)
                    closePoint(el)
                    $(el).find('.closeImg')[0].remove()

                } else {

                    await afterClick(el, currentTransform)
                    setDisablePan(true)
                    setDisableZoom(true)

                    document.querySelectorAll('video').forEach(vid => vid.pause());
                }

            }
        });
    }



    function closePoint(el){

        $(".buttonsControls").show()

        $(".video_player_div").show()

        $(el).addClass('hover_zoom')
        document.querySelectorAll('video').forEach(vid => vid.pause());



        document.querySelectorAll('.point').forEach(pt => $(pt).removeClass("zoomed") );
        

        let posEl = document.getElementById("zoomCanvas")
        posEl.style.transform = currentPos

        
        setDisablePan(false)
        setDisableZoom(false)
        setDisableParallax(false)

        firstSelectedPoint = true

        $('.point').removeClass( "photos_traslucid")

        // Check if we are mobile
        if(orientation == 'landscape'){
            backgroundAnimationZoomOut()
        } else {
            backgroundAnimationZoomOutResponsive()
        }

    }


    function afterClick(el, currentTransform){

        document.querySelectorAll('.point').forEach(pt => $(pt).removeClass("zoomed") );

        $(el).removeClass('hover_zoom')
        
        setDisableParallax(true)

        var hash = require('object-hash');

            $(".closeImg").remove();
            let arrPoint = $(el)[0]

            if(firstSelectedPoint == true){
                currentPos = document.getElementById("zoomCanvas").style.transform
            }

            firstSelectedPoint = false
            

            if(arrPoint.firstChild.className === 'wall_logo'){
                return null;
            }else{
                $('.point').addClass( "photos_traslucid" );
                $('.point').removeClass( "photos_animate_point")
                $(el).removeClass( "photos_traslucid")
                
                let closeImg = document.createElement("img");
                closeImg.src = closeImgSvg;
                closeImg.className ='closeImg';
                closeImg.style.display = 'block';
                $(closeImg).on("click touchstart", async function() {
                    closePoint()
                    $(this).remove();
                    $(el).addClass('hover_zoom')
                })

                $(el).append(closeImg);


                // FORMULA
                let point0 = $('.wall_logo').parent()[0]


                let left0 = parseInt(point0.style.left)
                let top0 = parseInt(point0.style.top)


                let valuePointLeft = parseInt(arrPoint.style.left);
                let valuePointTop = parseInt(arrPoint.style.top);
                let valueScreenWidth = window.innerWidth;
                let valueScreenHeight = window.innerHeight;


                let imgW = arrPoint.firstChild.clientWidth;
                let imgH = arrPoint.firstChild.clientHeight;


                if(valueScreenWidth < 560 && valueScreenWidth > 320 ){
                    let pedalScale = 1.05
                    let setScale = (valueScreenWidth * pedalScale) / 320

                    let getLeft = (   ( ( valuePointLeft * setScale ) - ( valueScreenWidth / 2 ) )   +   ( imgW  / setScale)  ) * (-1);
                    let getTop =  (   ( ( valuePointTop * setScale ) - ( valueScreenHeight/ 2 ) )   +    ( imgH / setScale )  ) * (-1);
                    $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');

                } else  if(valueScreenWidth <= 320) {

                    let setScale = 1.2

                    let getLeft = (   ( ( valuePointLeft * 1.2 ) - ( valueScreenWidth / 2 ) )   +   ( imgW / 1.65 )  ) * (-1);
                    let getTop =  (   ( ( valuePointTop * 1.2 ) - ( valueScreenHeight / 2 ) )   +    ( imgH / 1.2 )  ) * (-1);
                    $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
                    
                } else {
                    let getLeft = (   ( ( valuePointLeft * 2 ) - ( valueScreenWidth / 2 ) )   +    imgW  ) * (-1);
                    let getTop =  (   ( ( valuePointTop * 2 ) - ( valueScreenHeight/ 2 ) )   +    imgH  ) * (-1);
                    $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale(2)');
                }

                $(el).addClass("zoomed")

                // Check if we are mobile
                if(orientation == 'landscape'){
                    backgroundAnimationZoomIn()
                } else {
                    backgroundAnimationZoomInResponsive()
                }
            }
    }

    function zoomTo(element){

        var hash2 = require('object-hash');

        let content = document.querySelectorAll('.point');
        let myChilds = []
        let ids = []

        content.forEach(el => {
            myChilds.push(el.firstElementChild)
        })

        myChilds.forEach(child => {
            ids.push($(child).attr("id"))
        });
 

        ids.forEach(id =>{
            if(hash2(`${id}`) === element){

                const myElement = document.getElementById(id).parentElement

                $('.point').addClass( "photos_traslucid" );
                $('.point').removeClass( "photos_animate_point")
                $(myElement).removeClass( "photos_traslucid")


                $(myElement).click();

                if($(myElement).children().first()[0].className == "video_class"){

                    Array.from(document.querySelectorAll(".video_player_div"))
                        .forEach(function(val) {
                        val.style.display = 'none';
                    });

                    let myVideo2 =  $(myElement).children().first()[0]

                    if (myVideo2.paused == true) {
                        myVideo2.muted = true
                        myVideo2.play();
                    }
                
                }

                
                let closeImg = document.createElement("img");
                closeImg.src = closeImgSvg;
                closeImg.className ='closeImg';
                closeImg.style.display = 'block';
                $(myElement).append(closeImg);


                // // FORMULA
                let valuePointLeft = parseInt(myElement.style.left);
                let valuePointTop = parseInt(myElement.style.top);
                let valueScreenWidth = window.innerWidth;
                let valueScreenHeight = window.innerHeight;
                let imgW = myElement.firstChild.clientWidth;
                let imgH = myElement.firstChild.clientHeight;

                let getLeft = (   ( ( valuePointLeft * 2 ) - ( valueScreenWidth / 2 ) )   +    imgW  ) * (-1);
                let getTop =  (   ( ( valuePointTop * 2 ) - ( valueScreenHeight/ 2 ) )   +    imgH  ) * (-1);
                
                $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale(2)');
                setValue({scale: 2, translation: { x: getLeft, y: getTop } })

            }
        })
    }


    function zoomToOwnMessage(id){

        if(firstSelectedPoint == true){
            currentPos = document.getElementById("zoomCanvas").style.transform
        }

        firstSelectedPoint = false

        let arrPoint = $(id)[0]

        $('.point').addClass( "photos_traslucid" );
        $('.point').removeClass( "photos_animate_point")
        $(id).removeClass( "photos_traslucid")

        setDisablePan(true)
        setDisableZoom(true)
        
        let closeImg = document.createElement("img");
        closeImg.src = closeImgSvg;
        closeImg.className ='closeImg';
        closeImg.style.display = 'block';
        $(closeImg).on("click touchstart", async function() {
            $(".buttonsControls").show()

            $(this).remove();
            
            $(".video_player_div").show()
            document.querySelectorAll('video').forEach(vid => vid.pause());
            

            let posEl = document.getElementById("zoomCanvas")
            posEl.style.transform = currentPos

            setDisablePan(false)
            setDisableZoom(false)

            firstSelectedPoint = true

            $('.point').removeClass( "photos_traslucid")
        })

        $(id).append(closeImg);


        // FORMULA
        let point0 = $('.wall_logo').parent()[0]

        let left0 = parseInt(point0.style.left)
        let top0 = parseInt(point0.style.top)


        let valuePointLeft = parseInt(arrPoint.style.left);
        let valuePointTop = parseInt(arrPoint.style.top);
        let valueScreenWidth = window.innerWidth;
        let valueScreenHeight = window.innerHeight;


        let imgW = arrPoint.firstChild.clientWidth;
        let imgH = arrPoint.firstChild.clientHeight;


        if(valueScreenWidth < 560 && valueScreenWidth > 320 ){
            let pedalScale = 1.14
            let setScale = (valueScreenWidth * pedalScale) / 320

            let getLeft = (   ( ( valuePointLeft * setScale ) - ( valueScreenWidth / 2 ) )   +   ( imgW  / setScale)  ) * (-1);
            let getTop =  (   ( ( valuePointTop * setScale ) - ( valueScreenHeight/ 2 ) )   +    ( imgH / setScale )  ) * (-1);
            $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
            localStorage.setItem('messageUrl', '');

        } else  if(valueScreenWidth <= 320) {

            let setScale = 1.2

            let getLeft = (   ( ( valuePointLeft * 1.2 ) - ( valueScreenWidth / 2 ) )   +   ( imgW / 1.65 )  ) * (-1);
            let getTop =  (   ( ( valuePointTop * 1.2 ) - ( valueScreenHeight / 2 ) )   +    ( imgH / 1.2 )  ) * (-1);
            $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale('+setScale+')');
            localStorage.setItem('messageUrl', '');
            
        } else {
            let getLeft = (   ( ( valuePointLeft * 2 ) - ( valueScreenWidth / 2 ) )   +    imgW  ) * (-1);
            let getTop =  (   ( ( valuePointTop * 2 ) - ( valueScreenHeight/ 2 ) )   +    imgH  ) * (-1);
            $( "#zoomCanvas" ).css('transform', 'translate(' + getLeft +'px,'+ getTop +'px) scale(2)');
            localStorage.setItem('messageUrl', '');
        }
    }

    function handleChange(event) {
        setValue(event)
    }




    // PRESENTATION MODE

    let timeTranslate = 2;

    let currentTranslation = { x: 0, y: 0};
    
    let isVideoPlaying = false

    useEffect(() => {
      console.log('isVideoPlaying', isVideoPlaying)
    }, [isVideoPlaying])
    

    // let slideShowArray = []

    async function modePresentation(){

        let currentValue = valueWall;

        const array = document.querySelectorAll(".image");
        let arraySorted = [...array]

        console.log('array', array)

        // New Arrays orders 
        let firstFives = arraySorted.slice(0, 5);
        let NoFives = arraySorted.slice(5);

        for (let i = NoFives.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [NoFives[i], NoFives[j]] = [NoFives[j], NoFives[i]];
        }

        let NoFives_Fives = NoFives.slice(0, 5)

        var slideShowArray = $.merge([], firstFives);
        $.merge(slideShowArray, NoFives_Fives);

        console.log('slideShowArray', slideShowArray)

        // slideShowArray.concat(firstFives, NoFives_Fives)
        // slideShowArray.concat(NoFives_Fives)

        // console.log('slideShowArray', slideShowArray)

        // arraySorted.map( x => {
        //     console.log('x', x)

        //     slideShowArray.map(y => {

        //         console.log('y', y)
        //         // if(x.id != y.id ){


        //         // }
        //     })

            
        // })


        // Array for random
        for (var i = slideShowArray.length - 1; i > 0; i--) {
            var rand = Math.floor(Math.random() * (i + 1));
            [slideShowArray[i], slideShowArray[rand]] = [slideShowArray[rand], slideShowArray[i]]
        }
        // console.log(`arraySorted`, arraySorted)
        let index = 0;

        if($( "#modePresentation" ).hasClass("active") || !currentValue){
            $( "#modePresentation" ).removeClass("active")
            window.clearInterval(window.interval);
            $('.point').removeClass( "photos_traslucid" ); 
            setSlideShowActivate(false)
        } else {

            setSlideShowActivate(true)

        $( "#modePresentation" ).addClass("active")
        $('.point').removeClass( "photos_animate_point")

        $(".closeImg").remove();
        
        // Verify minimum of items
        if(slideShowArray.length >= 1){
            presentationZoom(slideShowArray, index);


            let currentItem = slideShowArray[index].childNodes
            console.log('currentItem', currentItem)

            // document.querySelectorAll('#currentItem > div');

            // for(var i = 0; i < currentItem.length; i++){
            //     if (currentItem[i].tagName == "VIDEO") {
            //         console.log('currentItem[i] es video', currentItem[i])
            //     } else if (currentItem[i].className == "video_vertical-container") {
            //         let children2 = currentItem[i].childNodes
            //         console.log('children2[1]', children2[1])
            //     }
            // }
    

            // if(currentItem[1] && currentItem[1].tagName == "VIDEO" || currentItem[0] && currentItem[0].tagName == "VIDEO"){

            //     isVideoPlaying = await true
            //     let video = currentItem[1]
            //     video.loop = false
            //     setTimeout(() => {
            //         currentItem[2].style.display = 'none'
            //         video.play()
            //     }, 1500);
            //     $(video).on('ended',function(){
            //         console.log('Video has ended!');
            //         isVideoPlaying = false
            //         currentItem[2].style.display = 'block'
            //         $(video).unbind( "ended" );
            //     });
            //     console.log('duration', currentItem[1].duration)
            //     isVideoPlaying = true
            // } else {
            //     isVideoPlaying = false
            // }


            for(var i = 0; i < currentItem.length; i++){
                if (currentItem[i].tagName == "VIDEO") {
                    isVideoPlaying = await true
                    let video = currentItem[1]
                    video.loop = false
                    setTimeout(() => {
                        currentItem[2].style.display = 'none'
                        video.play()
                    }, 1500);
                    $(video).on('ended',function(){
                        console.log('Video has ended!');
                        isVideoPlaying = false
                        currentItem[2].style.display = 'block'
                        $(video).unbind( "ended" );
                    });
                    console.log('duration', currentItem[1].duration)
                    isVideoPlaying = true
                } else if (currentItem[i].className == "video_vertical-container") {
                    let children2 = currentItem[i].childNodes
                    console.log('children2[1]', children2[1])

                    isVideoPlaying = await true
                    let videoVertical = children2[1]
                    videoVertical.loop = false
                    setTimeout(() => {
                        currentItem[1].style.display = 'none'
                        videoVertical.play()
                    }, 1500);
                    $(videoVertical).on('ended',function(){
                        console.log('Video has ended!');
                        isVideoPlaying = false
                        currentItem[1].style.display = 'block'
                        $(videoVertical).unbind( "ended" );
                    });
                    console.log('duration', children2[1].duration)

                }
            
            }



            if(slideShowArray.length == 1){
                index = 0
            } else {
                index = 1
            }
            

                window.interval = setInterval( async () => {
                    console.log('isVideoPlaying', isVideoPlaying)
                    if( !isVideoPlaying ){

                        let currentItem2 = slideShowArray[index].childNodes


                        for(var i = 0; i < currentItem2.length; i++){
                            if (currentItem2[i].tagName == "VIDEO") {
                                console.log('currentItem[i] es video', currentItem2[i])


                                isVideoPlaying = await true
                                let video2 = currentItem2[1]
                                video2.loop = false
                                setTimeout(() => {
                                    currentItem2[2].style.display = 'none'
                                    video2.play()
                                }, 1500);
                                $(video2).on('ended',function(){
                                    console.log('Video has ended!');
                                    isVideoPlaying = false
                                    currentItem2[2].style.display = 'block'
                                    $(video2).unbind( "ended" );
                                });
                                console.log('duration', currentItem2[1].duration)


                            } else if (currentItem2[i].className == "video_vertical-container") {
                                let children2 = currentItem2[i].childNodes
                                console.log('children2[1]', children2[1])

                                isVideoPlaying = await true
                                let videoVertical2 = children2[1]
                                videoVertical2.loop = false
                                setTimeout(() => {
                                    currentItem2[1].style.display = 'none'
                                    videoVertical2.play()
                                }, 1500);
                                $(videoVertical2).on('ended',function(){
                                    console.log('Video has ended!');
                                    isVideoPlaying = false
                                    currentItem2[1].style.display = 'block'
                                    $(videoVertical2).unbind( "ended" );
                                });
                                console.log('duration', children2[1].duration)

                            }
                        }



                        // if(currentItem2[1] && currentItem2[1].tagName == "VIDEO"){
                        //     isVideoPlaying = await true
                        //     let video2 = currentItem2[1]
                        //     video2.loop = false
                        //     setTimeout(() => {
                        //         currentItem2[2].style.display = 'none'
                        //         video2.play()
                        //     }, 1500);
                        //     $(video2).on('ended',function(){
                        //         console.log('Video has ended!');
                        //         isVideoPlaying = false
                        //         currentItem2[2].style.display = 'block'
                        //         $(video2).unbind( "ended" );
                        //     });
                        //     console.log('duration', currentItem2[1].duration)
                        //     isVideoPlaying = true
                        // } else {
                        //     isVideoPlaying = false
                        // }
                


                            presentationZoom(slideShowArray, index)
                            if(index === (slideShowArray.length - 1 )){

                                console.log('entro en la ultima')

                                index = 0;
                                window.clearInterval(window.interval);


                                let intervalEnd = window.setInterval(() => {
                                    
                                    if(!isVideoPlaying){
                                        clearInterval(intervalEnd)
                                        setTimeout(async() => {
                                            await s3.listObjectsV2(params, async function (err, data) {
                                                console.log('segunda entrada')
                                                setSecondKeyCount(data.KeyCount)
                        
                                                // console.log('data.KeyCount', data.KeyCount)
                                            })
                                            slideShowArray = []
                        
                                            // CallNewItems()
                                            // console.log('index vuelta', index)
        
                                        },  Math.round((timeTranslate * 1000) + 3000));
                                    }

                                }, 1000);

            

                                
                            }else{
                                index ++;
                            }

                            console.log('timeTranslate', timeTranslate)
                    }

                }, Math.round((timeTranslate * 1000) + 3000));
                

            } else {
                setTimeout(async() => {
                    await s3.listObjectsV2(params, async function (err, data) {
                        // console.log('segunda entrada')
                        setSecondKeyCount(data.KeyCount)

                        // console.log('data.KeyCount', data.KeyCount)
                    })
                    slideShowArray = []

                    // CallNewItems()
                    // console.log('index vuelta', index)
                }, 10000);
            }
        }
        
    }

    function presentationZoom(arraySorted, index){

        let timeTranslateZoom = 2

        console.log('timeTranslateZoom', timeTranslateZoom)


        $( "#zoomCanvas" ).removeClass("disabledDrag");
        // $('.point').addClass( "photos_traslucid" ); 

        $('.point').removeClass( "highlight" ); 
        
        //  FORMULA
        let valuePointLeft = parseInt(arraySorted[index].style.left);
        let valuePointTop = parseInt(arraySorted[index].style.top);
        let valueScreenWidth = window.innerWidth;
        let valueScreenHeight = window.innerHeight;
        let imgW = arraySorted[index].firstChild.clientWidth;
        let imgH = arraySorted[index].firstChild.clientHeight;

        let getLeft = (   ( ( valuePointLeft * 3 ) - ( valueScreenWidth / 2 ) )   +    (imgW * 1.5)   ) * (-1);
        let getTop =  (   ( ( valuePointTop * 3 ) - ( valueScreenHeight / 2 ) )   +    (imgH * 1.5)   ) * (-1);


        let distanceX = Math.abs(  (getLeft - currentTranslation.x )/ 720 )
        let distanceY = Math.abs(  (getTop - currentTranslation.y ) / 540  )

        let timeTranslation = 0;

        if(distanceX > distanceY){
            timeTranslation = distanceX
        } else {
            timeTranslation = distanceY
        }

        // console.log('Distance', distanceX, distanceY)
        // console.log('Gets ', getLeft, getTop)

        // if(timeTranslation <= 2000){
        //     timeTranslation = 1000
        // } else if( timeTranslation >= 4000 ) {
        //     timeTranslation = 1500
        // }
        
        // Transition Speed
        // timeTranslate = timeTranslation.toFixed(1) / 1.5
        // alert(timeTranslate)

        // console.log('timeTranslate', timeTranslate)

        $( "#zoomCanvas" ).css({'transform': 'translate(' + getLeft +'px,'+ getTop +'px) scale(3)', 'transition': `all ${timeTranslate}s ease-out 0s`});
        setValue({scale: 3, translation: { x: getLeft, y: getTop } })
        $(arraySorted[index]).removeClass( "photos_traslucid")

        setTimeout(() => {
            
            $(arraySorted[index]).addClass( "highlight")
        }, timeTranslateZoom * 1000);
        currentTranslation = {x : getLeft, y: getTop}
    }


    useEffect(() => {

        if(slideShowActivate){
            $('.buttonsControls').css( "display", 'none' ); 
            $('#wall').css( "display", 'none' ); 
            
        } else {
            window.clearInterval(window.interval);
            $('.logo').removeClass( "photos_traslucid" ); 


            // $('.image').addClass( "photos_traslucid" ); 
            $( "#zoomCanvas" ).css({'transform': 'translate(0px,0px) scale(1)', 'transition': `all 1s ease-out 0s`});
        }


    }, [slideShowActivate])





    useEffect(() => {

        setAnims(true)
        // setIntroVideoDisplay(false)
        console.log('secondKeyCount', secondKeyCount, firstKeyCount, context.readyVideo)
        if(secondKeyCount > 0 && firstKeyCount > 0 && context.readyVideo) {

            if(firstKeyCount != secondKeyCount){
                // console.log('Render Anim')

                setSlideShowActivate(false)

                setTimeout(() => {
                    setTimeout(async () => {
                       
                        $( "#modePresentation" ).removeClass("active")
                        // reRender()
                        setRenderNew(!renderNew)

                        // setLoadedImagesDOM(false)

                    }, 1100);
                    

                    $(".image").removeClass('highlight')
                    $('#container .point').removeClass('photos_traslucid')
                    $('#container .point').css( "opacity", "0" );

                    context.setRenderDisplay(true);
                    context.setPLayVideos(true);
                }, 2000);


                setTimeout(() => {
                    $('#container .image').addClass( "photos_traslucid" )
                    modePresentation();
                }, 18000);

            } else {

                setSecondKeyCount(0)
                // console.log('tengo que hacer randoms')

                $(".image").removeClass('highlight')
                $('#container .point').removeClass('photos_traslucid')
                $('#container .point').css( "opacity", "0" );

                $( "#modePresentation" ).removeClass("active")


                setSlideShowActivate(false)
                context.setRenderDisplay(true)
                context.setPLayVideos(true);

                // console.log('Mis Vars', context.playvideos, context.renderDisplay)


                setTimeout(() => {
                    // alert('vuelvo a hacer mode presentation')
                    // console.log('vuelvo a hacer mode presentation')
                    $('#container .image').addClass( "photos_traslucid" )
                    modePresentation();
                }, 18000);

            }
        }

    }, [secondKeyCount, context.readyVideo])
    

    


    async function CallNewItems(){

        let newKeyCount;

        await s3.listObjectsV2(params, async function (err, data) {
            // console.log('segunda entrada')
            setSecondKeyCount(data.KeyCount)
        })

        // console.log('OLD', firstKeyCount)
        // console.log('NEW', secondKeyCount)


        // setSlideShowActivate(false)

        // setTimeout(() => {
        //     setTimeout(async () => {
        //         reRender()
        //     }, 1100);
        //     context.setRenderDisplay(true)
        // }, 2000);
        
    }

    // function sumKeys(){
    //     console.log('sum', array2Length)
    //     array2Length = 35;

    //     setSlideShowActivate(false)

    //     setTimeout(() => {
    //         setTimeout(() => {
    //             $('#container').empty()
    //             $( "#modePresentation" ).removeClass("active")
    //             setRenderNew(!renderNew)
    //             setLoadedImagesDOM(false)
    //         }, 1100);
    //         context.setRenderDisplay(true)
    //     }, 2000);

       
    // }
    
    

    return (
        
        <>
            <>
                <div className={styles.load_container}>
                    <div className={styles.load_screen} ref={(el) => (screen = el)}>
                    </div>
                </div>

                <div id='qr_display' >
                    <img src='/assets/qr.png'></img>
                </div>

                <div id="containerWall" style={{ pointerEvents: 'none' }}>
                    <div id="zoomContainer" className={[styles.general_container, "zoomContainer"].join(' ')} > 
                        <MapInteractionCSS id={'mapInteraction'} draggingZoom={draggingZoom} disableParallax={disableParallax} disablePan={disablePan} disableZoom={disableZoom} onChange={(v) =>{ handleChange(v) }} >
                            <div id="container">
                            {/* <div className="point image logo photos_animate_point"       style={{left: '835px', top: '360px', background: 'none', border: 'none', boxShadow: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img src="https://walls-projects.s3.amazonaws.com/showyourpowerwith1mm/0.png" id="logo" className="wall_logo" style={{width: '250px'}} /></div> */}
                            </div>
                        </MapInteractionCSS>

                        <div id='footer' className={styles.footer}>
                            <p className={styles.swip_footer} ><img src={footer} alt="" /></p>
                        </div>

                    </div>
                </div>
            </>
        </>
    );
}
export default Display;