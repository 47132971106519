import React from 'react';

import styles from '../css/canvas.module.css';

import info from '../../assets/info.svg';

function CanvasTitle(){

    function gifInfo(){
        document.getElementById('info').style.display = 'block'
    }

return (
    <div className={styles.title_canvas}>
        {/* <img className={styles.info} src={info} />  */}
        {/* <img className={styles.info}  onClick={gifInfo} src={info}/> */}
        {/* <p className={styles.title_p}>Share with us what the</p>  */}
        <p className={styles.title_p2} >Share your congratulatory message with China! <span></span></p>
    </div>
    )
}

export default CanvasTitle;