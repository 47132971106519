import React, { useContext } from 'react';
import { CanvasContext } from '../../UserContext'
import { fabric } from 'fabric';

import styles from '../css/canvas.module.css';

import uploadImg from '../../assets/upload.svg'
import imageCompression from 'browser-image-compression';

function CanvasUpload(props){
    const context = useContext(CanvasContext)

    function importData() {
        context.canvas2.discardActiveObject().renderAll();
        context.canvas2.isDrawingMode = false;


        props.setActiveMenuDraw(false)
        props.setActiveMenuText(false)


        let input = document.createElement('input');
        input.type = 'file';
        input.id = 'my-image'
        input.accept="image/*"
        input.onchange = e => {
            handleImageUpload(e)
        };
        input.click();
    }

    async function handleImageUpload(event) {

        const imageFile = event.target.files[0];
        console.log('event', event)


        if(imageFile != null){

            context.canvas2.isDrawingMode = false;

            var canvasObjects = context.canvas2.getObjects(); // NOT WORKING

            if(canvasObjects[0].id == 'fabric_js'){
                context.canvas2.remove(canvasObjects[0]);
            }

            const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 300,
                useWebWorker: true,
                maxIteration: 20, 
            }
            try {
              const compressedFile = await imageCompression(imageFile, options);
          
              imgUpload(imageFile) // We call this function to mount the img into cavnvas
    
            } catch (error) {
                console.log(error);
            }
        }
    }

    function imgUpload(compressedFile){

        var reader = new FileReader();
        reader.onload = function(event){
            var img = new Image();

            img.onload = function(){

                var imgInstance = new fabric.Image(img, {
                    // angle: 25,
                    id:'fabric_js',
                    left: 0,
                    top: 0,
                    // hasControls: false
                });

                imgInstance.setControlsVisibility({
                    mt: true, 
                    mb: true, 
                    ml: true, 
                    mr: true, 
                    bl: true,
                    br: true, 
                    tl: true, 
                    tr: true,
                    mtr: true, 
               });

               imgInstance.scaleToHeight(255);
               imgInstance.scaleToWidth(340);

               context.canvas2.add(imgInstance);
               context.canvas2.sendToBack(imgInstance);
            }
            img.src = event.target.result;
        }
        reader.readAsDataURL(compressedFile);
    }

return (
    <button className={styles.select_button } onClick={importData}>
        <img className={styles.upload} src={uploadImg}/>
        <span className='tooltip_text'>Photo</span>
    </button>
    )
}

export default CanvasUpload;