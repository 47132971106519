import gsap from 'gsap';
import $ from "jquery"


const anims = {


    // LAYER

    showLayer: function(element){
        gsap.fromTo(`${element}` , {opacity: 0}, { duration: 0.7, opacity: 0.98, delay: 0, ease: 'Circ.out'  })
    },


    // FADES
    fadeOut: function(element, delay, cb){
        gsap.fromTo(`${element}` , {opacity: 1}, { duration: 0.7, opacity: 0, delay: delay, ease: 'Circ.out',onComplete: () => {
            if(cb){
                cb()
            }
        }}) 
    },

    fadeIn: function(element, delay, cb){
        gsap.fromTo(`${element}` , {opacity: 0}, { duration: 0.7, opacity: 1, delay: delay, ease: 'Circ.out',onComplete: () => {
            if(cb){
                cb()
            }
        }}) 
    },


    // SINGLUAR
    showElement: function(element, delay){
        gsap.fromTo(`${element}` , {opacity: 0, y: 60}, { duration: 0.7, opacity: 1, delay: delay, y: 0, ease: 'Circ.out'  }) 
    },
    hideElement: function(element, delay, cb){
        gsap.to(`${element}` , { duration: 0.7, delay: delay, opacity: 0, y: 60, ease: 'Circ.out',onComplete: () => {
            // console.log(`Termino Anims`)
            if(cb){
                cb()
            }
        }}) 
    },

    // PLURAL
    showElements: function(element, delay, cb){
        gsap.fromTo(`${element} .anim` , {opacity: 0, y: 60}, { duration: 0.7, opacity: 1,  stagger: 0.2, delay: delay, y: 0, ease: 'Circ.out', onComplete: () => {
            // console.log(`Termino Anims`)
            if(cb){
                cb()
            }
        }}) 
    },
    hideElements: function(element, delay, cb){
        gsap.to(`${element} .anim` , { duration: 0.7, stagger: 0.2, delay: delay, opacity: 0, y: 60, ease: 'Circ.out', onComplete: () => {
            // console.log(`Termino Anims`)
            if(cb){
                cb()
            }
        }})
    },

    // THUMBS WALL
    showThumbs: function(element){
        gsap.fromTo(`${element}`,

        {opacity: 0, y: 60},
        {
            duration: 1,
            scale: 1,
            opacity: 1,
            y: 0,
            ease: "power1.inOut",
            stagger: {
              from: "random",
              ease: "power2.in",
              amount: 1.5
            },
            onComplete: () => {
                // console.log(`Termino Thumbs`)
                $(element).addClass( "photos_traslucid" );
                anims.showElements("#wall", 1)
                $("#zoomContainer").removeClass( "zoomContainer" );
            }
          }
          );
    },

    // COMBINE
    hideAndShow: function(el1, el2, delay){
        gsap.to(`${el1}` , { duration: 0.7, delay: delay, opacity: 0, y: 60, ease: 'Circ.out', 
        onComplete: () => {
            gsap.fromTo(`${el2}` , {opacity: 0, y: 60}, { duration: 0.7, opacity: 1,  delay: 0.5 , y: 0, ease: 'Circ.out'  }) 
        }});
    },

    // TOOLS CANVAS
    showTool: function(element, delay, cb){
        gsap.fromTo(`${element}` , {opacity: 0, y: 20}, { duration: 0.7, opacity: 1, delay: delay, y: 0, ease: 'Circ.out', onComplete: () => {
            // console.log(`Termino Anims`)
            // cb()
        }})
    },

    showToolResponsive: function(element, delay, cb){
        gsap.fromTo(`${element}` , {opacity: 0, y: 10}, { duration: 0.7, opacity: 1, delay: delay, y: 0, ease: 'Circ.out', onComplete: () => {
            // console.log(`Termino Anims`)
            // cb()
        }})
    },


}


export default anims;
