import React, { useState, useEffect, useRef } from 'react'
import logo from '../assets/logo.png';


import closeBlack from '../assets/close-black.svg'
import angle_left from "../assets/angle-left-solid.svg"

import Cookies from "universal-cookie";

import Login from './Login'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isIPad13
  } from "react-device-detect";


import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";

import { withRouter } from "react-router-dom";

import styles from './css/user.module.css';

import useWindowSize from './WindowsSize';

import gsap from 'gsap';

import helpers from './Helpers/Anims';

import { ScaleContext } from '../UserContext';

import $ from 'jquery'


const User = (props) => {
    const size = useWindowSize();

    const context = React.useContext(ScaleContext)

    const cookies = new Cookies();


        // var orientation;
        const [landscape, setOrientation] = useState(false)
        const [onRotate, setOnRotate] = useState(false)

        useEffect( () => {
        
            context.setRender(false)
    
        }, [props.location.pathname])
    
        useEffect(() => {
    
            if (window.matchMedia("(orientation: portrait)").matches) {
                // alert("You are in portrait mode");
                // setOrientation("portrait")
                // orientation = "portrait"
                setOrientation(false)
            }
                
            if (window.matchMedia("(orientation: landscape)").matches) {
                // alert("You are in landscape mode");
                // setOrientation("landscape")
                // orientation = "landscape"
                setOrientation(true)
            }
    
            if ('onorientationchange' in window) {
                window.addEventListener("orientationchange", function() {
                    setOnRotate(!onRotate)
                }, false);
            }
        
        }, [onRotate])


    const [name, setName] = useState('')
    const [warning, setWarnign] = useState(false)
    const [warning1, setWarnign1] = useState(false)
    const [back, setBack] = useState(true)

    async function validateName(){
        var uagent = navigator.userAgent.toLowerCase();

        if (name === ''){
            setWarnign(true)
        }else{
            if(isMobile || isIPad13 ){
                // window.location.href = '/createresponsive'
                await localStorage.setItem('name', name);
                
                helpers.fadeOut("#user .anim", 0, goCreateResponsiveCb)

                
            } else { 
                await localStorage.setItem('name', name);
                console.log('name', name)

                // We send following params: 1: element to animate , 2: delay, 3: callback onComplete anim
                await helpers.fadeOut("#user .anim", 0, goCreateCb)

            }
        }
    }

    function goCreateCb(){
        props.history.push("/home")
    }

    function goCreateResponsiveCb(){
        props.history.push("/home")
    }


    function onKeyUp(event){
        if (event.key === 'Enter' ) {
            event.preventDefault();
            validateName();
        }
    }


    function onKeyUp(event){
        if (event.key === 'Enter' ) {
            event.preventDefault();
            validateName();
        }
    }

    const tlUser = useRef();
    const elUser = useRef();
    const userLogo = useRef();


    useEffect( () => {
        

        // tlUser.current = gsap.timeline({
        //     paused: false
        // })

        // tlUser.current.fromTo([elUser.current], {yPercent: 10, opacity: 0 }, {yPercent: 0, opacity: 1}, 0.2 );

        // helpers.showElement("#UserLogo", 0.5)
        helpers.showElements("#user", 1)

    }, [props.location.pathname, context.isAuth])


    function goHomeCb(){
        props.history.push("/view")
    }


    async function goHome(){
        await context.setRenderDisplay(false);
        await context.setPLayVideos(true);
        // We send following params: 1: element to animate , 2: delay, 3: callback onComplete anim
        await helpers.hideElements("#user", 0.5, goHomeCb)
    }

    return (

    <>
        { !context.isAuth ? 
            <Login />
        : 
        <>
            <p  style={{fontFamily: 'Crafty Girls', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Cedarville Cursive', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Rock Salt', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Homemade Apple', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Shadows Into Light', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Caveat', display: 'inline'}}></p>
            
            {landscape && isMobile && !isIPad13 ?
                <PopUpRotate/>
            :
            null }
            <div id="user" style={{position:'absolute', top:'0', zIndex: '10', left: 0}}>
                <div id="homeBgLayer" className={styles.bg}></div>

                <div className={styles.log}>
                    <div className={styles.log_divs}>
                        <img id="UserLogo" className={styles.logIMG} src="/assets/venture.png" ref={userLogo}></img>
                    </div>
                </div>
                {/* <Wall/> */}

            
                <div className={[styles.login, "anim"].join(' ')} >
                    <form className={styles.form} ref={elUser} autoComplete={'' + Math.random()}>
                        {/* <div className={styles.container_close} onClick={() => props.history.push("/home")}>
                            <div className={styles.close} >
                                <img className={styles.close_img} src={closeBlack} />
                            </div>
                        </div> */}
                        <div className={styles.one}>
                            <p className={styles.p, styles.primerOne}>Please, type <span style={{fontWeight: '600'}}>your Name</span><br/> below to identify your message</p>
                        </div>
                        
                        <div className={styles.two_1}>
                            {/* <p className={styles.p, styles.primerTwo}>First Name</p> */}
                            <input type="text" className={styles.input} placeholder="Example: John Smith" autocomplete="off" id="Password"  onChange={ ( e) => setName(e.target.value)} onFocus={() => setWarnign(false)} onKeyDown={(e) => onKeyUp(e)} />
                            { warning ? <p className={styles.alert}>Please type your Name above</p> : null }
                        </div>

                        <div className={styles.three}>
                            <button type="button"  className={styles.button} onClick={() => validateName()}>Continue</button>
                        </div>

                        <div className={styles.four}>
                            <button type="button"  className={styles.button_back} onClick={() => goHome()}> <p className={styles.p, styles.back}>Back</p></button>
                        </div>
                    </form>

                </div>

                <div className={[styles.footer , "anim"].join(' ')}>               
                    {/* <p className={styles.footerP, styles.footerSegundoP}>Powered by YAMA Group</p> */}
                </div>
            </div>
        </>
        }
    </>
    )
}

export default withRouter(User)
