import React, { useState, useEffect, useRef } from 'react'
import logo from '../assets/logo.png';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isIPad13
  } from "react-device-detect";


import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";


import { withRouter } from "react-router-dom";

import styles from './css/user.module.css';

import useWindowSize from './WindowsSize';

import { ScaleContext } from '../UserContext';

import helpers from './Helpers/Anims';

import gsap from 'gsap';

const User = (props) => {
    const size = useWindowSize();

    const context = React.useContext(ScaleContext)


    // var orientation;
    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)

    useEffect(() => {

        if (window.matchMedia("(orientation: portrait)").matches) {
            // alert("You are in portrait mode");
            // setOrientation("portrait")
            // orientation = "portrait"
            setOrientation(false)
        }
            
        if (window.matchMedia("(orientation: landscape)").matches) {
            // alert("You are in landscape mode");
            // setOrientation("landscape")
            // orientation = "landscape"
            setOrientation(true)
        }

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)
            }, false);
        }
    
    }, [onRotate])


    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [warning, setWarnign] = useState(false)
    const [warning1, setWarnign1] = useState(false)
    const [back, setBack] = useState(true)

    async function validateName(){
        var uagent = navigator.userAgent.toLowerCase();

        if (name === ''){
            setWarnign(true)
        }else{

            await localStorage.setItem('nameVideo', name);
            console.log('name', name)

            helpers.fadeOut("#UserLogo", 0)
            helpers.fadeOut("#userVideo", 0, goVideoCb)
            helpers.fadeOut("#footer", 0)
        }
    }

    function goVideoCb(){
        props.history.push("/video")
    }


    function onKeyUp(event){
        if (event.key === 'Enter' ) {
            event.preventDefault();
            validateName();
        }
    }


    function onKeyUp(event){
        if (event.key === 'Enter' ) {
            event.preventDefault();
            validateName();
        }
    }

    const tlUserVideo = useRef();
    const elUserVideo = useRef();
    const elUserVideoLogo = useRef();


    useEffect( () => {
        
        tlUserVideo.current = gsap.timeline({
            paused: false
        })

        helpers.showElements("#userVideo", 1)

    }, [props.location.pathname])

    async function goHomeCb(){
        props.history.push("/home")
    }

    async function goHome(){
        helpers.hideElements("#userVideo", 1, goHomeCb)
    }

    return (
        <>
            <p  style={{fontFamily: 'Crafty Girls', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Cedarville Cursive', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Rock Salt', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Homemade Apple', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Shadows Into Light', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Caveat', display: 'inline'}}></p>
        (
            {landscape && isMobile && !isIPad13 ?
                <PopUpRotate/>
            :
            null }
            <div id="userVideo" style={{position:'absolute', top:'0', zIndex: '10'}}>
                <div className={styles.log}>
                    <div className={styles.log_divs}>
                        <img id="UserLogo" className={styles.logIMG} ref={elUserVideoLogo} src="/assets/venture.png"></img>
                    </div>
                </div>
                {/* <Wall/> */}

            
                <div className={[styles.login, "anim"].join(' ')}>
                    <form className={styles.form} ref={elUserVideo} autoComplete={'' + Math.random()}>
                        {/* <div className={styles.container_close} onClick={() => props.history.push("/home")}>
                            <div className={styles.close} >
                                <img className={styles.close_img} src={closeBlack} />
                            </div>
                        </div> */}

                        <div className={styles.one}>
                            <p className={styles.p, styles.primerOne}>Please, type <span style={{fontWeight: 'bold'}}>your Name</span><br/> below to identify your message</p>
                        </div>
                        
                        <div className={styles.two_1}>
                            {/* <p className={styles.p, styles.primerTwo}>First Name</p> */}
                            <input type="text" className={styles.input} placeholder="Example: Jhon Smith" autocomplete="off" id="Password"  onChange={ ( e) => setName(e.target.value)} onFocus={() => setWarnign(false)} onKeyDown={(e) => onKeyUp(e)} />
                            { warning ? <p className={styles.alert}>Please type your Name above</p> : null }
                        </div>

                        <div className={styles.three}>
                            <button type="button"  className={styles.button} onClick={() => validateName()}>Continue</button>
                        </div>

                        <div className={styles.four}>
                            <button type="button"  className={styles.button_back} onClick={() => goHome()}> <p className={styles.p, styles.back}> Back</p></button>
                        </div>
                    </form>
                </div>

                <div className={[styles.footer, "anim"].join(' ')}>               
                    {/* <p className={styles.footerP, styles.footerSegundoP}>Powered by YAMA Group</p> */}
                </div>
            </div>
        )
        </>
    )
}

export default withRouter(User)
