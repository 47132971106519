import React, { useContext } from 'react';
import { CanvasContext } from '../../UserContext'
import { fabric } from 'fabric';

import styles from '../css/canvas.module.css';

import exclamation from '../../assets/exclamation-mark.svg';

import { ScaleContext } from '../../UserContext';

import helpers from '../Helpers/Anims';

function Clear(props){

    const context = React.useContext(ScaleContext)

    function cancelClear(){
        document.getElementById('clear').style.display = 'none'
    }

    function goHomeCB() {
        props.history.push("/home" , { from: 'Canvas' })
    }

    const clearCanvas = () => { 
        // context.h = [];
        // let canvasObjects = context.canvas2.getObjects();

        // context.canvas2.clear() //Limpio el canvas
        // addText2();
        // context.textType.current.style.display = 'none';  
        // context.inputref.current.style.display = 'none';
        // document.getElementById("my-image").value = "";

        // texts.splice(1,1)

        document.getElementById('clear').style.display = 'none'

        helpers.fadeOut("#canvas", 0, goHomeCB)
        helpers.fadeOut("#canvasResponsive",  0, goHomeCB)

    }

    function addText2() {  //Genero la funcion de agregar textos
        context.canvas2.isDrawingMode = false;
        context.inputref.current.style.display = 'none';
        
        context.textType.current.style.display = 'inline-block';
        
        if(context.name || context.last != null){
            context.textbox = new fabric.Textbox( context.name + " " + context.last, {
                fontSize: 45,
                left: 0,
                fontFamily: 'Caveat',
                top: 420,
                width: 620,
                textAlign: 'right',
                editable: false,
                evented: false,
                selectable: false,
                fill: 'white',
                });
                context.canvas2.add(context.textbox)
        }
    }

return (
    <div id='clear' className={styles.popupT} style={{display: 'none'}}>
        <div className={styles.popup_innerT}>
            <div className={styles.div_warning_popup}>
                <img src={exclamation} /> 
            </div>  
            <div className={styles.container_popupT}>
                <div className={styles.div_img_popupT}>
                <p className={styles.p_popupT2}>Discard post? </p>
                    <p className={styles.p_popupT}>If you quit now, all changes will be lost. </p>
                </div>
                <div className={styles.div_img_popupD}>
                    <button onClick={cancelClear} className={styles.button_popupT}>No, Keep editing</button>
                    <button onClick={clearCanvas} className={styles.button_popupV}>Yes, delete</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Clear;