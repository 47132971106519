import React, { useContext } from 'react';
import { CanvasContext } from '../../UserContext'
import { fabric } from 'fabric';
import Hammer from 'hammerjs'
import styles from '../css/canvas.module.css';
import uploadImg from '../../assets/upload.svg'
import imageCompression from 'browser-image-compression';

function CanvasUploadResponsive(props) {
    const context = useContext(CanvasContext)

    async function handleImageUpload(event) {
        context.canvas2.discardActiveObject().renderAll();
        context.canvas2.isDrawingMode = false;
        props.setActiveMenuDraw(false)
        props.setActiveMenuText(false)

        const imageFile = event.target.files[0];

        if (imageFile != null) {
            var canvasObjects = context.canvas2.getObjects(); // NOT WORKING
            if (canvasObjects[0].id == 'fabric_js') {
                context.canvas2.remove(canvasObjects[0]);
            }

            const options = {
                // maxSizeMB: 0.3,
                // maxWidthOrHeight: 300,
                // useWebWorker: true,
                // maxIteration: 20,
                alwaysKeepResolution: true
            }
            try {
                const compressedFile = await imageCompression(imageFile, options);
                imgUpload(compressedFile) // We call this function to mount the img into cavnvas
            } catch (error) {
                console.log(error);
            }
        }
    }

    function imgUpload(compressedFile) {
        var reader = new FileReader();
        reader.onload = function (event) {
            var img = new Image();
            img.onload = function () {
                var imgInstance = new fabric.Image(img, {
                    id: 'fabric_js',
                    left: 90,
                    top: 120,
                    originX: 'center',
                    originY: 'center',
                    minScaleLimit: 0.05,
                    cornerSize: 18,
                });
                imgInstance.setControlsVisibility({
                    mt: true,
                    mb: true,
                    ml: true,
                    mr: true,
                    bl: true,
                    br: true,
                    tl: true,
                    tr: true,
                    mtr: true,
                });

                imgInstance.scaleToHeight(140);
                imgInstance.scaleToWidth(180);
                context.canvas2.add(imgInstance);
                context.canvas2.sendToBack(imgInstance);


                var bbScope = imgInstance;

                console.log('bbScope', bbScope)

                var hammer = new Hammer.Manager(context.canvas2.upperCanvasEl);
                var pinch = new Hammer.Pinch({
                    threshold: 0.3,
                    mtr: true,
                });
                hammer.add([pinch]);

                let imageX = imgInstance.scaleX
                let imageY = imgInstance.scaleY

                var preAngle = 0;
                var rotateAngle = 0;
                var currentAngle;

                let adjustRotation = 0;

                hammer.on('pinch', function (ev) {
                    // Set the scale and render only if we have a valid pinch (inside the object)
                    if (bbScope._validPinch) {
                        bbScope.set('scaleX', ev.scale  * imageX);
                        bbScope.set('scaleY', ev.scale * imageY);

                        if(preAngle == 0){
                            //recode previous angle
                            preAngle = Math.round(ev.rotation);
                        }else{
                            currentAngle = (rotateAngle + Math.round(ev.rotation)-preAngle);
                            bbScope.rotate(currentAngle)
                        } 


                        bbScope.canvas.renderAll();
                    }
                });

                hammer.on('pinchend', function (ev) {
                    bbScope._validPinch = false;

                    rotateAngle = currentAngle;
                    preAngle = 0;
                });

                hammer.on('pinchcancel', function (ev) {
                    bbScope._validPinch = false;

                    rotateAngle = currentAngle;
                    preAngle = 0;
                });

                hammer.on('pinchstart', function (ev) {
                    // Convert mouse coordinates to canvas coordinates
                    ev.clientX = ev.center.x;
                    ev.clientY = ev.center.y;
                        

                    // Check if the pinch was started inside this object
                    if (bbScope.canvas) {
                        var p = bbScope.canvas.getPointer(ev);
                        bbScope._validPinch = bbScope.containsPoint(p);
                    } else {
                        bbScope._validPinch = false;
                    }
                });

            }
            img.src = event.target.result;
        }
        reader.readAsDataURL(compressedFile);
    }

    return (
        <button className={styles.select_button2} onClick={() => { document.getElementById('upload-input').click(); }}>
            <img className={styles.upload} src={uploadImg} alt="upload" />
            <p className={styles.p_description}>Photo</p>
            <input id="upload-input" value="" type="file" accept="image/*" name="upload" style={{ opacity: '0', position: 'absolute' }} onChange={handleImageUpload} />
        </button>
    )
}

export default CanvasUploadResponsive;
