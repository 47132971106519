
import React , { useState, useEffect, useRef } from "react";

import Wall from './Wall';
import styles from './css/login.module.css';
import logo from '../assets/logo.png';
import footer from '../assets/footer-powered-by.svg'
import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";

import Cookies from "universal-cookie";

import Bowser from "bowser";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isIPad13
  } from "react-device-detect";

  import history from "../history";

import { gsap, TimelineMax, Power3, Power4 } from "gsap";
import $ from 'jquery'


import helpers from './Helpers/Anims';


import { ScaleContext } from '../UserContext';


const Home = (props) => {

    const context = React.useContext(ScaleContext)

    var screen = context.screen
    var body = context.body

    const cookies = new Cookies();
    


    // var orientation;
    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)

    useEffect(() => {

        context.setRender(false)

        if (window.matchMedia("(orientation: portrait)").matches) {
            // alert("You are in portrait mode");
            // setOrientation("portrait")
            // orientation = "portrait"
            setOrientation(false)
        }
            
        if (window.matchMedia("(orientation: landscape)").matches) {
            // alert("You are in landscape mode");
            // setOrientation("landscape")
            // orientation = "landscape"
            setOrientation(true)
        }

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)
            }, false);
        }
    
    }, [onRotate])



    const [code, setCode] = useState('')
    const [warning, setWarning] = useState(false)


    // Gsap
    const elHome = useRef();
    const elHomeLogo = useRef();
    const elHomeFooter = useRef();
    // const q = gsap.utils.selector(el);
    const tlHome = useRef();



    // UseEffect to do Barba js transition
    // let screen = useRef(null);
    // let body = useRef(null);



    useEffect(() => {

        helpers.showElements("#login")
        // $('body').css('background-size', 'inherit')

        // Order matters
        // tlHome.current.fromTo([elHomeLogo.current], {yPercent: 10, opacity: 0 }, {yPercent: 0, opacity: 1}, 0.5 );

        // tlHome.current.fromTo([elHome.current], {yPercent: 10, opacity: 0 }, {yPercent: 0, opacity: 1}, 0.8 );

        // tlHome.current.fromTo([elHomeFooter.current], {yPercent: 10, opacity: 0 }, {yPercent: 0, opacity: 1}, 1.1 );

        

    }, [])

    function Recommend(){
        document.getElementById('recommend').style.display = 'none'
    }

    function ConfirmationCode(){
        if( code.toUpperCase() !== 'VENAWARD24' ){
            setWarning(true)
        }else{
            cookies.set('auth', 'true');
            console.log(cookies.get('auth'))
            context.setIsAuth(true)
        }
    }

    function onKeyUp(event){
        if (event.key === 'Enter' ) {
            event.preventDefault();
            ConfirmationCode();
        }
    }

    return (
        <>


        {landscape && isMobile && !isIPad13 ?
            <PopUpRotate/>
         :
        null }


        
        <div ref={elHomeLogo} id="login" className={styles.bg} style={{position:'absolute', top:'0'}}>
            <div className={[styles.log, "anim"].join(' ')}>
                
                <div className={styles.log_divs}>
                    <img className={styles.logIMG} src={'/assets/venture.png'} ></img>
            </div>
            
            </div>
            {/* <Wall/> */}
            <div className={[styles.login, "anim"].join(' ')}>
        
                <form className={styles.form} ref={elHome}>
                    <div className={styles.one}>
                        <p className={styles.p, styles.primerOne}>Welcome to the <br/> <b>digital wall of winners</b></p>
                    </div>
                    
                    <div className={styles.two}>
                        <p className={styles.p, styles.primerTwo}>Insert the code provided on the email</p>        
                        <input type="text" value={code} placeholder="CODE" className={styles.input} id="Password" autoComplete="off"  onChange={ ( e) => setCode(e.target.value)} onFocus={() => setWarning(false)} onKeyDown={(e) => onKeyUp(e)}/>
                        { warning ? <p className={styles.alert}>Please check your code </p> : null }
                    </div>
                    <div className={styles.three}>
                        <button type="button"  className={styles.buttonLogin} onClick={(e) => ConfirmationCode(e)}>Continue</button>
                    </div>
                </form>
            
            </div>
            <div className={[styles.footer, "anim"].join(' ')}>
                <p className={styles.footerP, styles.footerSegundoP}><img src={footer} alt=""  ref={elHomeFooter}/></p>   
            </div>
        </div>
        

            <div id='recommend' className={styles.popup} style={{display: 'none'}}>
                <div className={styles.popup_inner}>
                    <div className={styles.container_popup}>
                        <div className={styles.div_img_popup}>
                            <p className={styles.p_popup}> There are some features that may not be available using this browser <br/><br/> Please Consider using Chrome.</p>
                        </div>
                        <div className={styles.send_container}>
                            <button className={styles.send_button} onClick={ Recommend }>OK</button>
                        </div>
                    </div>
                </div>
            </div> 
        </>
      );
};

export default Home;
