import React from 'react';

import styles from '../css/canvas.module.css';

function CatchError(props){

    function uploadErrorAlert(){
        document.getElementById('uploadError').style.display = 'none'
    }

return (
    <div id='uploadError' className={styles.popup} style={{display: 'none'}}>
        <div className={styles.popup_inner}>
            <div className={styles.container_popup}>
                <div className={styles.div_img_popup}>
                    <p className={styles.p_popup}>There has been an error trying to upload your message, please try again.</p>
                </div>
                <div className={styles.send_container}>
                    <button className={styles.send_button} onClick={  uploadErrorAlert }>OK</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CatchError;