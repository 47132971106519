import React, { useContext } from 'react';
import { CanvasContext } from '../../UserContext'

import styles from '../css/canvas.module.css';

import done from '../../assets/doneDrawing.svg'

function CanvasSelectImg(props){

    const context = useContext(CanvasContext)

    function selectImg(){

        // document.getElementById('empty_container').style.display = 'none'

        props.setActiveMenuDrawFunction(false)

        // console.log(`props`, props)
        
        // context.textType.current.style.display = 'none';
        context.canvas2.isDrawingMode = false;
        // context.inputref.current.style.display = 'none';

        context.canvas2.getObjects().forEach( async function(o) {
            if(o.id == 'fabric_js') {
                context.canvas2.setActiveObject(o);
            }
        })
    }

return (
    <button onClick={selectImg} className={styles.select_button}>
        <img className={styles.selector} src={done} />
        <p className={styles.drawing_p_done}> Done </p>
        {/* <span className='tooltip_text'>Select</span> */}
    </button>
    )
}

export default CanvasSelectImg;