import React , { useContext, useEffect } from 'react';
import { fabric } from 'fabric';
import { CanvasContext } from '../../UserContext'

import styles from '../css/canvas.module.css';

import tool_text from '../../assets/tool_text.svg';


import helpers from '../Helpers/Anims';

function CanvasText(props){
    const context = useContext(CanvasContext)
    let  textbox;

    useEffect(() => {
        console.log('props.activeMenuDraw', props.activeMenuDraw)
        if(props.activeMenuDraw){
            props.setActiveMenuText(false);
            context.canvas2.discardActiveObject()
            context.canvas2.renderAll();
        }
      }, [props.activeMenuDraw])

    async function addText() {

        await props.setActiveMenuText(!props.activeMenuText)

        helpers.showTool("#textMenu")

        if(props.activeMenuBg){
            props.setActiveMenuBg(false)
        }

        if(props.activeMenuDraw){
            props.setActiveMenuDraw(false)
        }

        context.obj = context.canvas2.getObjects()
        
        let texts = [];

        context.obj.map((el, id) => {
            if(el.text !== undefined){
                texts.push(el)
            }
        })
        

        if( texts.length == 2){
            context.canvas2.isDrawingMode = false;

            textbox = new fabric.Textbox("Type your message here", {
                fontSize: 35,
                left: 80,
                top: 160,
                padding: 40,
                id: 'textbox',
                width: 480,
                textAlign: 'center',
                fill: '#fff',
                fontFamily: 'Open Sans',
            });
            context.canvas2.discardActiveObject()
            // context.canvas2.setActiveObject(textbox)
            // textbox.enterEditing();

            textbox.selectAll()

            context.canvas2.add(textbox);
            context.canvas2.bringForward(textbox);
            
            textbox.on('changed', function() {
                var currentCharacters = textbox.text.length;
                let newFontSize;
                console.log('currentCharacters', currentCharacters)
                
                if(currentCharacters < 50){
                    newFontSize = 35;
                    textbox.set('fontSize', newFontSize);
                } else if(currentCharacters >= 50 && currentCharacters < 70){
                    newFontSize = 30;
                    textbox.set('fontSize', newFontSize);
                } else if (currentCharacters >= 100 && currentCharacters < 150){
                    newFontSize = 25;
                    textbox.set('fontSize', newFontSize);
                } else if (currentCharacters >= 150 ){
                    newFontSize = 22;
                    textbox.set('fontSize', newFontSize);
                }
    
            })

        } else {
            context.canvas2.isDrawingMode = false;
            const objetoEncontrado = texts.find(objeto => objeto.id === "textbox");
            console.log('objetoEncontrado', objetoEncontrado)
            let textbox1 = objetoEncontrado;
            context.canvas2.setActiveObject(textbox1)
            textbox1.enterEditing();
        }
    }

    async function setText( param, value){

        if(context.canvas2.getActiveObject()){
            var obj = context.canvas2.getActiveObject();

            if(obj.id === 'textbox'){
                obj.set( param, value);
                context.canvas2.renderAll();
                context.canvas2.discardActiveObject()
                context.canvas2.setActiveObject(obj)
                obj.enterEditing()
                context.canvas2.renderAll();
            }
        }
    }


return (
    <>
        <button id='textSelector' onClick={addText} className={styles.select_button}>
            <img className={styles.select_img} src={tool_text} /> 
            <span className='tooltip_text'>Text</span>
        </button>

        { props.activeMenuText ? 
            <div id="textMenu" className={styles.optionsDiv} >
                <select ref={props.textType} className={styles.select_text} onChange={(e) => setText('fontFamily', props.textType.current.value)}>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Crafty Girls">Crafty Girls</option>
                    <option value="Cedarville Cursive">Cedarville Cursive</option>
                    <option value="Rock Salt">Rock Salt</option>
                    <option value="Homemade Apple">Homemade Apple</option>
                    <option value="Shadows Into Light">Shadows Into Light</option>
                </select>
            </div>
        : null }
    </>
    )
}

export default CanvasText;